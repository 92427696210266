.pt-0\.5,
.py-0\.5,
.p-0\.5 {
  padding-top: 0.125rem !important;
}

.pb-0\.5,
.py-0\.5,
.p-0\.5 {
  padding-bottom: 0.125rem !important;
}

.pl-0\.5,
.px-0\.5,
.p-0\.5 {
  padding-left: 0.125rem !important;
}

.pr-0\.5,
.px-0\.5,
.p-0\.5 {
  padding-right: 0.125rem !important;
}

.pt-1\.5,
.py-1\.5,
.p-1\.5 {
  padding-top: 0.325rem !important;
}

.pb-1\.5,
.py-1\.5,
.p-1\.5 {
  padding-bottom: 0.325rem !important;
}

.pl-1\.5,
.px-1\.5,
.p-1\.5 {
  padding-left: 0.325rem !important;
}

.pr-1\.5,
.px-1\.5,
.p-1\.5 {
  padding-right: 0.325rem !important;
}

.pt-2\.5,
.py-2\.5,
.p-2\.5 {
  padding-top: 0.625rem !important;
}

.pb-2\.5,
.py-2\.5,
.p-2\.5 {
  padding-bottom: 0.625rem !important;
}

.pl-2\.5,
.px-2\.5,
.p-2\.5 {
  padding-left: 0.625rem !important;
}

.pr-2\.5,
.px-2\.5,
.p-2\.5 {
  padding-right: 0.625rem !important;
}

.pt-4\.5,
.py-4\.5,
.p-4\.5 {
  padding-top: 2rem !important;
}
.pb-4\.5,
.py-4\.5,
.p-4\.5 {
  padding-bottom: 2rem !important;
}

.pl-4\.5,
.px-4\.5,
.p-4\.5 {
  padding-left: 2rem !important;
}
.pr-4\.5,
.px-4\.5,
.p-4\.5 {
  padding-right: 2rem !important;
}

.ml-n1,
.mx-n1,
.m-n1 {
  margin-left: -0.25rem !important;
}

.mr-n1,
.mx-n1,
.m-n1 {
  margin-right: -0.25rem !important;
}

.mt-n1,
.my-n1,
.m-n1 {
  margin-top: -0.25rem !important;
}

.mb-n1,
.my-n1,
.m-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n2,
.mx-n2,
.m-n2 {
  margin-left: -0.5rem !important;
}

.mr-n2,
.mx-n2,
.m-n2 {
  margin-right: -0.5rem !important;
}

.mt-n2,
.my-n2,
.m-n2 {
  margin-top: -0.5rem !important;
}

.mb-n2,
.my-n2,
.m-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n3,
.mx-n3,
.m-n3 {
  margin-left: -1rem !important;
}

.mr-n3,
.mx-n3,
.m-n3 {
  margin-right: -1rem !important;
}

.mt-n3,
.my-n3,
.m-n3 {
  margin-top: -1rem !important;
}

.mb-n3,
.my-n3,
.m-n3 {
  margin-bottom: -1rem !important;
}

.ml-n4,
.mx-n4,
.m-n4 {
  margin-left: -1.5rem !important;
}

.mr-n4,
.mx-n4,
.m-n4 {
  margin-right: -1.5rem !important;
}

.mt-n4,
.my-n4,
.m-n4 {
  margin-top: -1.5rem !important;
}

.mb-n4,
.my-n4,
.m-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4\.5,
.mx-n4\.5,
.m-n4\.5 {
  margin-left: -2rem !important;
}

.mr-n4\.5,
.mx-n4\.5,
.m-n4\.5 {
  margin-right: -2rem !important;
}

.mt-n4\.5,
.my-n4\.5,
.m-n4\.5 {
  margin-top: -2rem !important;
}

.mb-n4\.5,
.my-n4\.5,
.m-n4\.5 {
  margin-bottom: -2rem !important;
}

.mr-1\.5,
.mx-1\.5,
.m-1\.5 {
  margin-right: 0.325rem !important;
}

.mt-2\.5,
.my-2\.5,
.m-2\.5 {
  margin-top: 0.625rem !important;
}

.mb-2\.5,
.my-2\.5,
.m-2\.5 {
  margin-bottom: 0.625rem !important;
}

.ml-2\.5,
.mx-2\.5,
.m-2\.5 {
  margin-left: 0.625rem !important;
}

.ml-4\.5,
.mx-4\.5,
.m-4\.5 {
  margin-left: 2rem !important;
}

.mr-4\.5,
.mx-4\.5,
.m-4\.5 {
  margin-right: 2rem !important;
}

.mt-4\.5,
.my-4\.5,
.m-4\.5 {
  margin-top: 2rem !important;
}

.mb-4\.5,
.my-4\.5,
.m-4\.5 {
  margin-bottom: 2rem !important;
}

.ml-n5,
.mx-n5,
.m-n5 {
  margin-left: -3rem !important;
}

.mr-n5,
.mx-n5,
.m-n5 {
  margin-right: -3rem !important;
}

.mt-n5,
.my-n5,
.m-n5 {
  margin-top: -3rem !important;
}

.mb-n5,
.my-n5,
.m-n5 {
  margin-bottom: -3rem !important;
}
.mb-0\.5,
.my-0\.5,
.m-0\.5 {
  margin-bottom: 0.125rem !important;
}
.mt-0\.5,
.my-0\.5,
.m-0\.5 {
  margin-top: 0.125rem !important;
}
.ml-0\.5,
.mx-0\.5,
.m-0\.5 {
  margin-left: 0.125rem !important;
}
.mr-0\.5,
.mx-0\.5,
.m-0\.5 {
  margin-right: 0.125rem !important;
}

// sizes
:root {
  --size-0: 0px;
  --size-2: 0.125rem;
  --size-4: 0.25rem;
  --size-6: 0.325rem;
  --size-8: 0.5rem;
  --size-10: 0.625rem;
  --size-12: 0.75rem;
  --size-14: 0.825rem;
  --size-16: 1rem;
  --size-24: 1.5rem;
  --size-32: 2rem;
  --size-40: 2.5rem;
  --size-48: 3rem;
  --size-56: 3.5rem;
  --size-64: 4rem;
  --size-80: 5rem;
}

$sizes: 0, 2, 4, 6, 8, 10, 12, 14, 16, 24, 32, 40, 48, 56, 64, 80;

@mixin layout-loop($selector, $property, $prefix: '-') {
  .#{$selector} {
    @each $size in $sizes {
      &-#{$size} {
        #{$property}: var(--size-#{$size});
      }
    }
  }
}

// Margins
// @include layout-loop('mg', 'margin');
// @include layout-loop('mt', 'margin-top');
// @include layout-loop('mb', 'margin-bottom');
// @include layout-loop('ml', 'margin-left');
// @include layout-loop('mr', 'margin-right');

// Paddings
// @include layout-loop('pd', 'padding');
// @include layout-loop('pt', 'padding-top');
// @include layout-loop('pb', 'padding-bottom');
// @include layout-loop('pl', 'padding-left');
// @include layout-loop('pr', 'padding-right');

// Grid Gap
@include layout-loop('gap', 'gap');
