//
// Form-Upload
//

/* Dropzone */
.dropzone {
  min-height: 80px;
  border: 2px dashed $gray-400;
  background: $card-bg;
  border-radius: 6px;

  .dz-message {
    font-size: 24px;
    width: 100%;
  }
}

.dropzone-custom {
  border-style: solid;
  border-width: 1px;
  transition: 0.15s ease-in-out;
  transition-property: border-color, background-color;

  &:hover {
    background-color: lighten($primary, 46%);
  }
}
