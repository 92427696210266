.bordered-nav-tabs {
  border: 1px solid #eeeff1 !important;
  border-radius: 4px;
  width: 100%;
  display: flex;
  .rp-nav-tabs {
    .nav-item {
      margin-left: 24px;
      button {
        padding: 22px 0 !important;
        &:hover:not(.active) {
          background-color: transparent !important;
        }
      }
    }
  }
  .trail {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 24px;
  }
}
