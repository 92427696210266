.form-group legend {
  font-weight: 500;
  font-size: inherit;
}

@media (max-width: 754px) {
  .custom-modal-footer.custom-modal-footer {
    width: 100%;
    max-width: 686px;
    margin: auto !important;

    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1030;
  }
}
