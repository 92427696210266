.pin-code {
  --ReactInputVerificationCode-itemWidth: 2.5rem;
  --ReactInputVerificationCode-itemHeight: 3rem;
}

.pin-code .ReactInputVerificationCode__container {
  max-width: 100%;
}

@media (min-width: 768px) {
  .pin-code {
    --ReactInputVerificationCode-itemWidth: 4rem;
    --ReactInputVerificationCode-itemHeight: 4rem;
  }
}

.pin-code .ReactInputVerificationCode__item {
  box-shadow: inset 0 0 0 1px var(--gray-b);
  font-weight: 400;
}
.pin-code .ReactInputVerificationCode__item.is-active {
  box-shadow: inset 0 0 0 2px var(--primary);
}

.pin-code.is-invalid .ReactInputVerificationCode__item {
  border: 1px solid var(--danger);
}
