.min-h-0 {
  min-height: 0px;
}
.min-h-full {
  min-height: 100%;
}
.min-h-screen {
  min-height: 100vh;
}
.min-h-min {
  min-height: min-content;
}
.min-h-max {
  min-height: max-content;
}
.min-h-fit {
  min-height: fit-content;
}
