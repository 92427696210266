.credit-card-content {
  width: 339px;
  height: 211px;
  @media (max-width: 768px) {
    width: 311px;
    height: 195px;
  }
  box-shadow:
    0px 6.519230842590332px 13.038461685180664px 0px rgba(0, 0, 0, 0.08),
    0px 6.519230842590332px 55.41345977783203px 0px rgba(15, 16, 53, 0.16);
  border-radius: 15.123px;
  background: linear-gradient(134deg, #0f1035 0%, rgba(15, 16, 53, 0.85) 100%);
}
