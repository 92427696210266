.nav-dots {
  display: flex;
  gap: 8px;
  position: absolute;
  margin-left: 43% !important;
  > span {
    height: 8px;
    width: 8px;
    background-color: #d9d9d9;
    border-radius: 50%;
    display: inline-block;
    cursor: pointer;
  }
  > span.active {
    background-color: #424652;
  }
}
