.rp-nav-tabs {
  border-bottom: 0;
  .nav-item {
    &:not(:last-child) {
      margin-right: 0.5rem;
    }
    .nav-link {
      border-width: 0 0 2px 0;

      &:hover:not(.active) {
        border-color: transparent transparent currentColor !important;
        background-color: var(--gray-200) !important;
      }
    }

    .active {
      border-bottom-color: var(--primary) !important;
      color: var(--primary) !important;
    }
  }
}
