.scopeField :global(.ql-container) {
  padding: 0;
  color: var(--gray-600);
  font-size: 0.8rem;
}

.scopeField :global(.ql-editor) {
  padding: 0.65rem 0.75rem;
  height: 15ch;
}

.scopeField :global(.ql-editor.ql-blank::before) {
  left: 1.25rem;
  right: 0.75rem;
  color: var(--gray-600);
}

.scopePopover {
  box-shadow:
    0px 0px 4px 0px rgba(0, 0, 0, 0.04),
    0px 0px 35px -10px rgba(0, 0, 0, 0.16);
  max-width: 95% !important;
}

@media (min-width: 754px) {
  .scopePopover {
    width: 730px !important;
  }
}
