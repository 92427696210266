.shimmer {
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;

  background: linear-gradient(
    100deg,
    rgba(255, 255, 255, 0) 20%,
    rgba(227, 227, 227, 0.5) 50%,
    rgba(255, 255, 255, 0) 80%
  );

  animation: shimmer infinite linear;
  animation-duration: var(--duration, 1s);
}

@keyframes shimmer {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(200%);
  }
}
