//
// _table.scss
//

.table {
  th {
    font-weight: $font-weight-bold;
  }

  th,
  td {
    padding: 1rem 0.75rem;
  }

  &.sticky-header {
    thead tr {
      position: sticky;
      top: $header-height;
      border-bottom: 2px solid #eff2f7;
      backdrop-filter: blur(3px);
      background: rgba(255, 255, 255, 0.9);
      z-index: 9;
    }
  }
}

//Table centered
.table-centered {
  td,
  th {
    vertical-align: middle !important;
  }
}

.table-nowrap {
  th,
  td {
    white-space: nowrap;
  }
}
