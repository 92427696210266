.credit-card-component {
  position: relative;
  transition-duration: 300ms;
  transition-property: transform, box-shadow;
  transition-timing-function: ease-out;
  border-radius: 1rem;
  z-index: 10;

  &:hover {
    transition-duration: 150ms;
    box-shadow: 0 5px 20px 5px #00000044;
  }

  .glow {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    transition-duration: 300ms;
    transition-timing-function: ease-out;
    z-index: 1;
    pointer-events: none;
    border-radius: 1rem;

    background-image: radial-gradient(circle at 50% -20%, #ffffff22, #0000000f);
  }
}
