.slider-modal {
  width: auto !important;
  height: auto !important;
  max-height: calc(100% - 3.5rem);
  @media (max-width: 575px) {
    margin: 1rem !important;
  }
  .modal-content {
    border-radius: 8px;
    .carousel-inner {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }
  }
}
.slide-text {
  min-height: 175px;
}
.slider-modal-indicators {
  margin: 0 !important;
  z-index: 0 !important;
  bottom: calc(50% - 14px);
  pointer-events: visibleFill;
  background-color: transparent;
  li {
    background-color: var(--surface-30) !important;
    &.active {
      background-color: var(--secondary-100) !important;
    }
  }
}
