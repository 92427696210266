//
// backgrounds.scss
//

.bg-gray-bg {
  background-color: $gray-bg;
}

@each $color, $value in $theme-colors {
  .bg-soft-#{$color} {
    background-color: rgba(($value), 0.1) !important;
  }

  .border-soft-#{$color} {
    border-color: rgba(($value), 0.1) !important;
  }
  .border-soft-#{$color} {
    border-color: rgba(($value), 0.25) !important;
  }
}

@each $color, $value in $theme-colors {
  .hover\:bg-#{$color}:hover {
    background-color: $value !important;
  }
}

@each $color, $value in $theme-colors {
  .hover\:bg-soft-#{$color}:hover {
    background-color: rgba(($value), 0.25) !important;
  }
}
