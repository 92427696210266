//
// Table editable
//

.table-editable {
  .editable-input {
    .form-control {
      height: 2rem;
    }
  }
  a.editable {
    color: $table-color;
  }
  .editable-buttons {
    .btn {
      &.btn-sm {
        font-size: 12px;
      }
    }
  }
  tbody {
    td {
      &.focus {
        box-shadow: inset 0 0 1px 1px $primary !important;
      }
    }
  }
}

.glyphicon {
  display: inline-block;
  font-family: 'Material Design Icons';
  font-size: inherit;
  font-weight: 600;
  font-style: inherit;
}

.glyphicon-ok {
  &:before {
    content: '\F012C';
  }
}

.glyphicon-remove {
  &:before {
    content: '\F0156';
  }
}

.dt-autofill-list {
  border: none !important;
  background-color: $card-bg !important;

  .dt-autofill-question,
  .dt-autofill-button {
    border-bottom-color: $gray-300 !important;
  }

  ul {
    li {
      &:hover {
        background-color: $gray-300 !important;
      }
    }
  }
}
