//
// _menu.scss
//

.metismenu {
  margin: 0;
  margin-bottom: 1rem;

  @media (min-width: 754px) {
    margin-bottom: 0;
  }

  .mm-collapse {
    display: none;

    &:not(.mm-show) {
      display: none;
    }

    &.mm-show {
      display: block;
    }
  }

  .mm-collapsing {
    position: relative;
    height: 0;
    overflow: hidden;
    transition-timing-function: ease;
    transition-duration: 0.35s;
    transition-property: height, visibility;
  }
}

.vertical-menu {
  width: $sidebar-width;
  z-index: 1;
  // background: $sidebar-bg;
  background-color: $sidebar-dark-bg;
  bottom: 0;
  margin-top: 0;
  position: fixed;
  top: $header-height;
  box-shadow: $box-shadow;
}

.main-content {
  overflow: visible;
  min-height: 90vh;

  .content {
    padding: 0 15px 10px 15px;
    margin-top: $header-height;
  }
}

@media (min-width: 992px) {
  .main-content,
  .footer {
    margin-left: $sidebar-width;
  }
}

.menu-title {
  padding: 12px 20px !important;
  letter-spacing: 0.05em;
  pointer-events: none;
  cursor: default;
  font-size: 11px;
  text-transform: uppercase;
  color: $sidebar-menu-item-icon-color;
  font-weight: $font-weight-semibold;
}

@media (max-width: 992px) {
  .vertical-menu {
    display: none;
  }

  body.sidebar-enable {
    .vertical-menu {
      display: block;
    }
  }
}

// Enlarge menu
.vertical-collpsed {
  .main-content {
    margin-left: $sidebar-collapsed-width;
  }

  .navbar-brand-box {
    width: $sidebar-collapsed-width !important;
  }

  .logo {
    .logo-lg {
      display: none;
    }

    .logo-sm {
      display: block;
    }
  }

  // Side menu
  .vertical-menu {
    position: absolute;
    width: $sidebar-collapsed-width !important;
    z-index: 5;

    .simplebar-mask,
    .simplebar-content-wrapper {
      overflow: visible !important;
    }

    .simplebar-scrollbar {
      display: none !important;
    }

    .simplebar-offset {
      bottom: 0 !important;
    }

    // Sidebar Menu
    #sidebar-menu {
      .menu-title,
      .badge,
      .collapse.in {
        display: none !important;
      }

      .nav.collapse {
        height: inherit !important;
      }

      > ul {
        > li {
          position: relative;
          white-space: nowrap;

          > a {
            padding: 10px 20px;
            min-height: 40px;
            transition: none;

            &:hover,
            &:active,
            &:focus {
              color: $sidebar-menu-item-hover-color;
            }

            i {
              font-size: 1.45rem;
              margin-left: 4px;
            }

            span {
              display: none;
              padding-left: 25px;
            }
          }

          &:hover {
            > a {
              position: relative;
              width: calc(190px + #{$sidebar-collapsed-width});
              color: $primary;
              background-color: darken($sidebar-bg, 4%);
              transition: none;

              i {
                color: $primary;
              }

              span {
                display: inline;
              }
            }

            > ul {
              display: block;
              left: $sidebar-collapsed-width;
              position: absolute;
              width: 190px;
              height: auto !important;
              box-shadow: 3px 5px 10px 0 rgba(54, 61, 71, 0.1);

              ul {
                box-shadow: 3px 5px 10px 0 rgba(54, 61, 71, 0.1);
              }

              a {
                box-shadow: none;
                padding: 8px 20px;
                position: relative;
                width: 190px;
                z-index: 6;
                color: $sidebar-menu-sub-item-color;

                &:hover {
                  color: $sidebar-menu-item-hover-color;
                }
              }
            }
          }
        }

        ul {
          padding: 5px 0;
          z-index: 9999;
          display: none;
          background-color: $sidebar-bg;

          li {
            &:hover {
              > ul {
                display: block;
                left: 190px;
                height: auto !important;
                margin-top: -36px;
                position: absolute;
                width: 190px;
              }
            }

            > a {
              span.pull-right {
                position: absolute;
                right: 20px;
                top: 12px;
                transform: rotate(270deg);
              }
            }
          }
        }
      }
    }
  }
}

body[data-sidebar='dark'] {
  .vertical-menu {
    background: $sidebar-dark-bg;
  }

  // Enlarge menu
  &.vertical-collpsed {
    min-height: 1300px;
    // Side menu
    .vertical-menu {
      // Sidebar Menu
      #sidebar-menu {
        > ul {
          > li {
            &:hover {
              > a {
                background: lighten($sidebar-dark-bg, 2%);
              }
            }
          }

          ul {
            background-color: $sidebar-dark-bg;
          }
        }
      }
    }
  }

  .menu-title {
    color: $sidebar-dark-menu-item-icon-color;
  }
}

body[data-layout='horizontal'] {
  .main-content {
    margin-left: 0 !important;
  }
}

// Compact Sidebar

body[data-sidebar-size='small'] {
  .navbar-brand-box {
    width: $sidebar-width-sm;
  }
  .vertical-menu {
    width: $sidebar-width-sm;
    text-align: center;

    .has-arrow:after,
    .badge {
      display: none !important;
    }
  }
  .main-content {
    margin-left: $sidebar-width-sm;
  }
  .footer {
    left: $sidebar-width-sm;
  }

  #sidebar-menu {
    ul li {
      &.menu-title {
        background-color: lighten($sidebar-dark-bg, 2%);
      }
      a {
        i {
          display: block;
        }
      }
      ul.sub-menu {
        li a {
          padding-left: 1.5rem;
        }
      }
    }
  }
  &.vertical-collpsed {
    .main-content {
      margin-left: $sidebar-collapsed-width;
    }
    .vertical-menu {
      #sidebar-menu {
        text-align: left;
        > ul {
          > li {
            > a {
              i {
                display: inline-block;
              }
            }
          }
        }
      }
    }
    .footer {
      left: $sidebar-collapsed-width;
    }
  }
}

// colored sidebar

body[data-sidebar='colored'] {
  .vertical-menu {
    background-color: $primary;
  }
  .navbar-brand-box {
    background-color: $primary;
    .logo-dark {
      display: none;
    }
    .logo-light {
      display: block;
    }
  }

  .mm-active {
    color: $white !important;
    > i,
    .active {
      color: $white !important;
    }
  }

  #sidebar-menu {
    ul {
      li {
        &.menu-title {
          color: rgba($white, 0.6);
        }

        a {
          color: rgba($white, 0.6);
          i {
            color: rgba($white, 0.6);
          }
          &.waves-effect {
            .waves-ripple {
              background: rgba($white, 0.1);
            }
          }
        }

        ul.sub-menu {
          li {
            a {
              color: rgba($white, 0.5);
            }
          }
        }
      }
    }
  }

  &.vertical-collpsed {
    .vertical-menu {
      #sidebar-menu {
        > ul {
          > li {
            &:hover > a {
              background-color: lighten($primary, 2%);
              color: $white;
              i {
                color: $white;
              }
            }
          }
        }

        ul {
          li {
            &.mm-active {
              .active {
                color: $sidebar-menu-item-active-color !important;
              }
            }
          }
        }
      }
    }
  }
}
