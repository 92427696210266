.blur-image {
  background-image: url('./../../assets/images/contractBlur-min.png');
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
}
.blur-container {
  min-height: calc(100vh - 56px);
}

@media (min-width: 768px) {
  .blur-container {
    min-height: calc(100vh - 80px);
  }
}
