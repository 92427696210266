.react-tel-input {
  font-family: inherit;
}

.react-tel-input .form-control {
  height: 42px !important;
  border: 1px solid var(--gray-b) !important;
  box-sizing: border-box !important;
  border-radius: 4px !important;
  width: 100% !important;
  font-size: 14px !important;
}
.react-tel-input .border-danger {
  border-color: #dc3545 !important;
}

.flag-dropdown {
  border: 0 !important;
  background-color: #fff !important;
  margin: 1px !important;
}

.react-tel-input .form-control:disabled + .flag-dropdown {
  background-color: var(--surface-30);
}

.flag .arrow {
  display: none !important;
}

.selected-flag {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  padding: 0 !important;
  padding-inline-start: 16px !important;
}
