@import './icons.scss';

@import './global.css';

// Dark Theme
// @import "./bootstrap-dark.scss";
// @import "./app-dark.scss";

// RTL Mode
//@import "./bootstrap.scss";
//@import "./app-rtl.scss";

// Light Theme
@import './app.scss';

#tag_input::placeholder {
  color: '#1C1C1C';
  font-family: Inter;
  font-weight: 400;
  font-style: normal;
  font-size: 12px;
}

.noti-icon .badge {
  left: 23px;
}

.dataTables_filter,
.dataTables_paginate {
  float: right;
}

.rdw-editor-main {
  border: 1px solid $gray-300;
  height: 239px;
}

.dz-message {
  text-align: center;
  padding: 30px;
}

.border-primary {
  border-color: var(--primary) !important;
}

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0px 0 0px 2px rgba(50, 90, 231, 0.2);
}

.custom-control-input:checked ~ .custom-control-label::before {
  background-color: var(--primary) !important;
  border-color: var(--primary) !important;
}

.custom-control-input:disabled ~ .custom-control-label {
  cursor: default;
}

.custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: var(--primary-70) !important;
  border-color: var(--primary-70) !important;
}

.bg-primary {
  background-color: var(--primary) !important;
}

.text-primary {
  color: var(--primary) !important;
}

a {
  color: var(--primary);
}

a:hover {
  cursor: pointer;
}

@media (max-width: 1280px) {
  .font-size-14 {
    font-size: 13px !important;
  }

  .font-size-16 {
    font-size: 14px !important;
  }

  td {
    padding: 1rem !important;
  }

  th {
    padding: 1rem !important;
  }
}

.avatar-title {
  background-color: var(--primary) !important;
}

.task-box {
  border: 1px solid $border-color;
}

.react-datepicker-wrapper {
  width: 100% !important;
}

.ReactModal__Overlay {
  z-index: 1001 !important;
}

.chat-conversation .right .conversation-list {
  margin-right: 15px;
}

.external-event {
  &:hover {
    cursor: pointer;
  }
}
.rating-container {
  background-color: transparent !important;
}

.input-group-append {
  z-index: 0;
  height: 44px;
}

.input-group-append:where([dir='rtl'], [dir='rtl'] *) {
  margin-inline-start: -1px;
  margin-left: 0;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group
  > .input-group-append:last-child
  > .btn:not(:last-child):not(.dropdown-toggle),
.input-group
  > .input-group-append:last-child
  > .input-group-text:not(:last-child),
.input-group > .custom-select:not(:last-child),
.input-group > .form-control:not(:last-child) {
  border-start-end-radius: 0;
  border-end-end-radius: 0;
  border-start-start-radius: $input-border-radius;
  border-end-start-radius: $input-border-radius;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group
  > .input-group-prepend:first-child
  > .input-group-text:not(:first-child),
.input-group > .custom-select:not(:first-child),
.input-group > .form-control:not(:first-child) {
  border-start-start-radius: 0;
  border-end-start-radius: 0;
  border-start-end-radius: $input-border-radius;
  border-end-end-radius: $input-border-radius;
}

.input-color {
  color: $input-color !important;
}

.sketch-picker {
  position: absolute;
  z-index: 1;
}

.rangeslider__fill {
  background-color: $primary !important;
}

.calendar-icon-container {
  padding: $input-padding-y $input-padding-x $input-padding-y $input-padding-y;
}

.react-select-container {
  height: $input-height;
  padding: $input-padding-y $input-padding-x $input-padding-y $input-padding-y;
  width: 100%;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #0f1035;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.RS-Control__control {
  padding: $input-padding-y * 0.45 $input-padding-x * 0.7 $input-padding-y *
    0.45 !important;
  border-color: $input-border-color !important;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  box-shadow: rgba(255, 255, 255, 0) 0 0 0 0px !important;

  &--is-disabled {
    background-color: var(--light) !important;
  }
}

.input-group .RS-Addon__control {
  padding: $input-padding-y * 0.4 $input-padding-x * 0.7 $input-padding-y * 0.45 !important;
  border-color: $input-border-color;
  border-radius: 0 4px 4px 0;
  box-shadow: rgba(255, 255, 255, 0) 0 0 0 0px !important;
  height: 44px;
  width: 100%;
  max-width: 150px;

  &--is-focused {
    border-color: #c6c8e0 !important;
    box-shadow: 0 0 0 2px rgba(198, 200, 224, 0.4) !important;
  }

  @media (max-width: 576px) {
    max-width: 125px;
  }
}

.input-group:not(.has-validation) > .form-control:not(:last-child),
.input-group:not(.has-validation) > .custom-select:not(:last-child),
.input-group:not(.has-validation)
  > .custom-file:not(:last-child)
  .custom-file-label,
.input-group:not(.has-validation)
  > .custom-file:not(:last-child)
  .custom-file-label::after {
  border-inline-end-color: transparent;
}

.RS-Addon__value-container {
  display: flex !important;
  flex-wrap: nowrap !important;
}

.RS-Approve__control__menu {
  min-height: 400px !important;
}

// hide burger menu default button
#react-burger-menu-btn {
  display: none !important;
}

.bm-burger-bars {
  display: none !important;
}

// custom modal for mobile
@media (max-width: 754px) {
  .modal-dialog:not(.native-dialog-sm) {
    width: 100%;
    margin: 0 auto;
    padding: 0;

    .modal-content {
      height: auto;
      min-height: 100vh;
      border-radius: 0;
      border: 0;
    }
  }
}

@media (min-width: 576px) {
  .modal-medium {
    max-width: 470px;
  }
}

.intercom-lightweight-app-launcher:where([dir='rtl'], [dir='rtl'] *),
.intercom-namespace
  .intercom-with-namespace-2nvnet:where([dir='rtl'], [dir='rtl'] *) {
  right: auto !important;
  left: 20px;
}

.intercom-lightweight-app-launcher {
  bottom: 5rem !important;
}

@media (min-width: 754px) {
  .intercom-lightweight-app-launcher {
    bottom: 20px !important;
  }
}

.intercom-lightweight-app-launcher,
.intercom-lightweight-app {
  z-index: 1049 !important;
}

// style react-input-verification-code
.verificationCode {
  .ReactInputVerificationCode__container {
    width: 100%;
    justify-content: center;
    gap: 0.5rem;
  }

  .ReactInputVerificationCode__item {
    --ReactInputVerificationCode-itemWidth: 40px;
    --ReactInputVerificationCode-itemHeight: 40px;
    box-shadow: 0px 0px 0px 1px #e7e8f2;

    &.is-active {
      box-shadow: 0px 0px 0px 2px #114ef7;
    }
  }

  @media (min-width: 576px) {
    .ReactInputVerificationCode__container {
      gap: 1rem;
    }

    .ReactInputVerificationCode__item {
      --ReactInputVerificationCode-itemHeight: 5rem;
      --ReactInputVerificationCode-itemWidth: 4.5rem;
    }
  }
}

.apexcharts-tooltip {
  box-shadow: none;
}

.apexcharts-with-hover {
  .apexcharts-bar-area:hover {
    fill: #114ef7 !important;
  }
}

.btn-success-disabled {
  background-color: rgba(34, 199, 153, 0.15);
  border-color: rgba(34, 199, 153, 0.15);
  color: #22c799;

  &:hover {
    color: #22c799;
  }
}

.btn-warning-disabled {
  background-color: var(--gold-20);
  border-color: var(--gold-30);
  color: var(--gold-110);

  &:hover {
    color: var(--gold-110);
  }
}

.btn-danger-cancel {
  background-color: var(--red-20);
  border-color: var(--red-20);
  color: var(--red-80);

  &:hover {
    background-color: var(--red-30);
    border-color: var(--red-30);
    color: var(--red-90);
  }
}

@media (max-width: 1280px) and (min-width: 754px) {
  .btn:not(.btn-sm) {
    padding: $btn-padding-y 1rem;
  }
}

.bg-soft-lightRed {
  background-color: rgba(226, 87, 87, 0.15) !important;
}

.bg-soft-self-badge {
  background-color: rgba(119, 127, 158, 0.15);
}

.bg-soft-self-badge * {
  color: #777f9e !important;
}

.step-container-content {
  margin-bottom: 5.6rem;
}

.creditCardModal,
.uploadModal,
.newAccountModal,
.contractModal {
  max-width: 686px;
}

.modal-sm-2 {
  max-width: 412px;
}

.p-card-col {
  padding: 1.5rem !important;

  @media (max-width: 1280px) {
    padding: 1.3rem !important;
  }
}

.card-icon {
  height: 32px;
  width: 32px;

  @media (max-width: 1280px) {
    height: 24px;
    width: 24px;
  }
}

.contract-avatar {
  height: 5.4rem;
  width: 5.4rem;

  @media (max-width: 1280px) {
    height: 4.4rem;
    width: 4.4rem;
  }
}

.with-search__control {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  height: 44px;
  border-color: $input-border-color !important;
  border-inline-end-width: 0px !important ;
  min-width: 100px;
  box-shadow: rgba(255, 255, 255, 0) 0 0 0 0px !important;
}

select {
  appearance: none;
  background: transparent;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='20' viewBox='0 0 20 20' width='20' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h20v20H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 95%;
  background-position-y: 47%;
  margin-right: 2rem;
  padding: 1rem;
  padding-right: 2rem;
}

select.react-datepicker__month-select {
  padding: 8px 16px 8px 8px !important;
  border: 0px !important;
  margin-right: 0;
}

select.react-datepicker__year-select {
  padding: 8px 16px 8px 8px !important;
  border: 0px !important;
  margin-right: 0;
}

.react-datepicker__header__dropdown--select {
  display: flex;
  gap: 12px;
  padding-inline: 12px;
}
.react-datepicker__month-dropdown-container--select,
.react-datepicker__year-dropdown-container--select {
  flex: 1;
  margin-inline: 0;
}
.react-datepicker__month-select,
.react-datepicker__year-select {
  width: 100%;
  direction: ltr;
}

.react-datepicker__ {
  &day,
  &month-text,
  &quarter-text,
  &year-text {
    &--today {
      color: var(--primary) !important;
    }
  }

  &day--selected,
  &day--in-selecting-range,
  &day--in-range,
  &month-text--selected,
  &month-text--in-selecting-range,
  &month-text--in-range,
  &quarter-text--selected,
  &quarter-text--in-selecting-range,
  &quarter-text--in-range,
  &year-text--selected,
  &year-text--in-selecting-range,
  &year-text--in-range,
  &day--keyboard-selected,
  &month-text--keyboard-selected,
  &quarter-text--keyboard-selected,
  &year-text--keyboard-selected {
    background-color: var(--primary) !important;
    color: white !important;
  }

  &day--keyboard-selected,
  &month-text--keyboard-selected,
  &quarter-text--keyboard-selected,
  &year-text--keyboard-selected {
    box-shadow: 0 0 12px -2px var(--primary);
  }
}

.spanblock {
  background: #f2f5fc;
  border: 1px solid rgba(50, 90, 231, 0.25);
  border-radius: 2px;
  color: #114ef7;
  font-size: 12px;
  padding: 4px 6px 3px 6px;
}

.tags-container {
  > #accordion {
    overflow: auto;
    max-height: 90vh;
    min-height: calc(100vh - var(--header-height));
    z-index: 101;
    position: sticky;
    right: 0;
    top: var(--header-height);
  }

  .tag {
    cursor: pointer;
  }
}

.ql-mention-list {
  background-color: white;
  position: absolute;
  z-index: 99999;
  min-width: 200px;
  border: 1px solid $gray-200;
  border-radius: 3px;
  padding: 10px;
  overflow: scroll;
  max-height: 40vh;
  box-shadow: 0px 0px 0px 1px #e7e8f2;
}

.ql-mention-list-item {
  padding-bottom: 0.5rem !important;
}

#scope-contract-info .ql-container {
  display: block;
  padding: 0;
  font-family: $input-font-family;
  @include font-size($input-font-size);
  font-weight: $input-font-weight;
  line-height: $input-line-height;
  color: $input-color;
  background-color: $input-bg;
  background-clip: padding-box;
  border: 1px solid #e7e8f2;
  box-sizing: border-box;
  border-radius: 4px;
}

#scope-contract-info .ql-editor {
  padding: $input-padding-y $input-padding-x;
  height: 130px !important;
}

.radio-button-select-card {
  border-width: 4px !important;
}

.upload-btn div {
  outline: none !important;
}

.my-popover .popover .arrow {
  left: 68px !important;
}

.my-popover .popover {
  box-shadow: 2px -2px 35px 9px rgba(0, 0, 0, 0.16);
  -webkit-box-shadow: 2px -2px 35px 9px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 2px -2px 35px 9px rgba(0, 0, 0, 0.16);
  min-width: 830px !important;
  left: 270px !important;
  border-radius: 0.2rem !important;
}

.unbo-popover .popover {
  border-radius: 6px !important;
}

@media (max-width: 1024px) {
  .my-popover .popover {
    min-width: 630px !important;

    left: 197px !important;
  }
}

.custom-popper {
  z-index: 99999 !important;
}

.border-indicator {
  border: 4px var(--primary) solid !important;
}

.sticky-col {
  @media (min-width: 992px) {
    position: sticky;
    background: white;
  }
}

.first-col {
  --col-width: 50px;
  width: var(--col-width);
  min-width: var(--col-width);
  max-width: var(--col-width);
  --col-left: 0px;
  left: var(--col-left);
}

.second-col {
  --col-width: 136px;
  width: var(--col-width);
  min-width: var(--col-width);
  max-width: var(--col-width);
  --col-left: 50px;
  left: var(--col-left);
}

.third-col {
  --col-width: 240px;
  width: var(--col-width);
  min-width: var(--col-width);
  max-width: var(--col-width);
  --col-left: 186px;
  left: var(--col-left);
}

.forth-col {
  --forth-col-width: 220px;
  width: var(--forth-col-width);
  min-width: var(--forth-col-width);
  max-width: var(--forth-col-width);
  --col-left: 390px;
  left: var(--col-left);
  z-index: 1;
}

.first-user-col {
  --col-width: 60px;
  width: var(--col-width);
  min-width: var(--col-width);
  max-width: var(--col-width);
  left: 0px;
  z-index: 990;
}

.second-user-col {
  --col-width: 150px;
  width: var(--col-width);
  min-width: var(--col-width);
  max-width: var(--col-width);
  left: 60px;
  z-index: 990;
}

.third-user-col {
  --col-width: 150px;
  width: var(--col-width);
  min-width: var(--col-width);
  max-width: var(--col-width);
  left: 210px;
  z-index: 990;
}

.fourth-user-col {
  --col-width: 250px;
  width: var(--col-width);
  min-width: var(--col-width);
  max-width: var(--col-width);
  left: 360px;
  z-index: 990;
}

.first-kycUser-col {
  --col-width: 126px;
  width: var(--col-width);
  min-width: var(--col-width);
  max-width: var(--col-width);
  left: 0px;
  z-index: 990;
}

.second-kycUser-col {
  width: 150px;
  min-width: 150px;
  max-width: 150px;
  left: 126px;
  z-index: 990;
}

.third-kycUser-col {
  width: 250px;
  min-width: 250px;
  max-width: 250px;
  left: 276px;
  z-index: 990;
}

.first-company-col {
  --col-width: 150px;
  width: var(--col-width);
  min-width: var(--col-width);
  max-width: var(--col-width);
  left: 0px;
  z-index: 990;
}

.second-company-col {
  --col-width: 140px;
  width: var(--col-width);
  min-width: var(--col-width);
  max-width: var(--col-width);
  left: 150px;
  z-index: 990;
}

.third-company-col {
  --col-width: 154px;
  width: var(--col-width);
  min-width: var(--col-width);
  max-width: var(--col-width);
  left: 290px;
  z-index: 990;
}

.first-bank-col {
  width: 180px;
  min-width: 180px;
  max-width: 180px;
  left: 0px;
  z-index: 990;
}

.second-bank-col {
  width: 180px;
  min-width: 180px;
  max-width: 180px;
  left: 180px;
  z-index: 990;
}

.scan-invoice-input {
  &:focus {
    outline: none !important;
  }
}

.no-outline {
  &:focus {
    outline: none;
  }
}

.requirement-description p {
  margin-bottom: 0;
}

.action-dropdown .dropdown-menu {
  left: -14px !important;
}

.flat-button:focus {
  box-shadow: none !important;
  outline: 0 !important;
}

.tag-input {
  display: flex;
  flex-wrap: wrap;
  min-height: 48px;
}

.tag-input input {
  flex: 1;
  border: none;
  height: 46px;
  font-size: 14px;
  padding: 4px 0 0;
}

.tag-input input:focus {
  outline: transparent;
}

.tags {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 2px 0 0;
  left: 0;
}

.tag-title {
  margin-top: 3px;
}

.tag-close-icon {
  display: block;
  width: 16px;
  height: 16px;
  line-height: 16px;
  text-align: center;
  font-size: 14px;
  margin-left: 8px;
  color: #0052cc;
  border-radius: 50%;
  background: #fff;
  cursor: pointer;
}

// Custom classes for storybook
.sb-main-padded.sb-show-main {
  background-color: #fff;
}

// Missing bootstrap styles
.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #000;
  background: transparent
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e")
    center/1em auto no-repeat;
  border: 0;
  border-radius: 0.25rem;
  opacity: 0.5;
}

.form-check:has(.form-check-input[type='radio']) {
  position: relative;

  &.form-check-inline {
    padding-left: 1.25rem;
  }
}

.form-check-input[type='radio'] {
  width: 14px;
  height: 14px;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  appearance: none;
  border-radius: 50%;
  margin: 0 0 0 4px !important;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  position: absolute !important;

  &:checked {
    background-color: var(--primary);
    border-color: var(--primary);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
  }

  &:focus {
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(198, 200, 224, 0.41);
  }

  ~ .form-check-label {
    padding-inline-start: 0.325rem !important;
  }
}

.form-check-inline {
  margin-right: 1.125rem;
}

.modal-header .close {
  padding: 0.325rem 0.5rem 0.5rem 0.5rem;
  margin: -0.325rem;

  height: 30px;
  width: 30px;

  &:not(:disabled):not(.disabled):focus {
    background-color: var(--light);
    border-radius: 4px;
  }
}

.text-current {
  color: currentColor;
}

// boxicons styles overrides
.bx-loader.fix-loading:before {
  display: block;
  margin-bottom: -2px;
}

// bootstrap styles overrides
hr {
  border-top-color: var(--gray-200);
}

.alert-orange {
  background-color: var(--orange-50);
  color: var(--orange-900);
}

// #region Custom classes
.rp-border {
  border: 1px solid #e7e8f2;
}

.rp-shadow {
  box-shadow:
    0px 0px 1px 0px #0000000a,
    0px 2px 6px 0px #0000000a,
    0px 10px 20px 0px #0000000a;
}

.rp-shadow-2 {
  box-shadow: 0px 1px 0px #dfe1e6;
}

.rp-font-mono {
  font-family: $font-family-monospace;
}

.rp-font-thin {
  font-weight: 100;
}

.rp-font-extralight {
  font-weight: 200;
}

.rp-font-light {
  font-weight: 300;
}

.rp-font-normal {
  font-weight: 400;
}

.rp-font-medium {
  font-weight: 500;
}

.rp-font-semibold {
  font-weight: 600;
}

.rp-font-bold {
  font-weight: 700;
}

.rp-font-extrabold {
  font-weight: 800;
}

.rp-font-black {
  font-weight: 900;
}

.rp-checkbox-label {
  font-family: var(--font-family-sans-serif);
  cursor: pointer;

  transition: 100ms ease;
  transition-property: background-color, box-shadow;

  box-shadow: 0 0 0px 4px transparent;

  &:hover {
    --color: rgba(50, 90, 231, 0.05);
    background-color: var(--color);
    box-shadow: 0 0 0px 6px var(--color);
  }
}

.rp-checkbox-input {
  --checkbox-size: 1.25rem;
  width: var(--checkbox-size);
  height: var(--checkbox-size);
  cursor: pointer;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  appearance: none;

  position: relative;
  margin-top: 0;
  margin-left: 0;
  transition: all 250ms ease;

  &[type='checkbox'] {
    border-radius: 2px;
  }

  &:disabled {
    cursor: not-allowed;
  }

  &:focus {
    border-color: #7e98f6;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(50, 90, 231, 0.25);
  }

  &:checked {
    --checkbox-color: var(--primary);
    background-color: var(--checkbox-color);
    border-color: var(--checkbox-color);

    &:disabled {
      --checkbox-color: #aebdf2;
    }

    &[type='checkbox'] {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
    }
  }

  &:indeterminate {
    --checkbox-color: var(--primary);
    background-color: var(--checkbox-color);
    border-color: var(--checkbox-color);
    &[type='checkbox'] {
      background-image: url('data:image/svg+xml,%3Csvg%20viewBox%3D%220%200%2016%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%3Cpath%20d%3D%22M2%208a1%201%200%20011-1h10a1%201%200%20110%202H3a1%201%200%2001-1-1z%22%20fill%3D%22%23fff%22%2F%3E%0A%3C%2Fsvg%3E');
    }
  }
}

.rp-btn-nostyle {
  border: none;
  background-color: transparent;
  padding: 2px 5px;
  border-radius: 4px;

  &:focus {
    box-shadow: 0 0 4px -2px;
  }
}

.rp-capitalize {
  text-transform: capitalize;
}

.rp-lowercase {
  text-transform: lowercase;
}

.rp-text-capitalize-first::first-letter {
  display: block;
  text-transform: capitalize;
}

.rp-max-w-100 {
  max-width: 100%;
}

.rp-font-mono {
  font-family: var(--font-family-monospace);
}

.text-balance {
  text-wrap: balance;
}

.transition-colors {
  transition-property:
    color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

@media (min-width: 768px) {
  .md\:w-25 {
    width: 25%;
  }

  .md\:w-50 {
    width: 50%;
  }

  .md\:w-75 {
    width: 75%;
  }

  .md\:w-100 {
    width: 100%;
  }
}

.appearance-none {
  appearance: none;
}

.underline {
  text-decoration: underline;
}

.hover\:underline:hover {
  text-decoration: underline;
}

.\!hover\:underline:hover {
  text-decoration: underline !important;
}

.tabular-nums {
  font-variant-numeric: tabular-nums;
}

.overflow-auto {
  overflow: auto;
}

.overflow-unset {
  overflow: unset;
}

.h-unset {
  height: unset;
}

.border-transparent {
  border-color: transparent !important;
}

.text-underline {
  text-decoration: underline;
}

.placeholder\:font-size-34::placeholder {
  font-size: 34px;
}

// #endregion

.form-control {
  height: 44px;
  color: var(--text-100);

  &:focus:not(:disabled):not([readonly]) {
    color: var(--text-100);
    border-color: #bbbdd6;
    box-shadow: 0 0 0 2px rgba(198, 200, 224, 0.41);
  }

  &:disabled,
  &[readonly] {
    background-color: var(--surface-30);
    color: var(--text-70);
    opacity: 1;
  }

  &::placeholder {
    color: var(--text-40);
  }
}

.rp-candy-pink-btn {
  --btn-color: #e56d68;
  background-color: var(--btn-color) !important;
  border-color: var(--btn-color) !important;

  &:hover {
    --btn-color: #cb5954;
  }
}

:root {
  --header-height: 56px;
  --header-height-anonym: var(--header-height);

  --page-content-padding: 1.5rem;

  --padding-content-x: 16px;
  --padding-content-top: calc(
    var(--header-height) + var(--page-content-padding)
  );
  --padding-content-bottom: 60px;
  --sidebar-width: #{$sidebar-width};
  --user-sidebar-width: 80px;

  @media (min-width: 768px) {
    --padding-content-x: 32px;

    --header-height: 70px;
    --header-height-anonym: 80px;
  }

  @media (min-width: 1280px) {
    --padding-content-x: 40px;
    --page-content-padding: 40px;
  }
}

.tags-input-container {
  border: 1px solid #e7e8f2;
  padding: 0.5em;
  border-radius: 3px;
  margin-top: 1em;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.5em;
}

.email-tag-item {
  background-color: #c2cbf859;
  border-radius: 4px;
  display: inline-block;
  padding: 0.5em 0.75em;
}

.email-tag-item .close {
  align-items: center;
  background-color: #fff;
  border-radius: 50%;
  color: #000af2;
  cursor: pointer;
  display: inline-flex;
  font-size: 14px;
  height: 20px;
  justify-content: center;
  margin-left: 0.5em;
  width: 20px;
}

.email-tags-input {
  flex-grow: 1;
  padding: 0.5em 0;
  border: none;
  outline: none;
}

.tab-content-sm-margin {
  margin-bottom: 10rem;

  @media (min-width: 768px) {
    margin-bottom: 4rem;
  }
}

.rounded-top-0 {
  border-start-start-radius: 0 !important;
  border-start-end-radius: 0 !important;
}

.rounded-bottom-0 {
  border-end-start-radius: 0 !important;
  border-end-end-radius: 0 !important;
}

.rounded-right-0 {
  border-start-end-radius: 0 !important;
  border-end-end-radius: 0 !important;
}

.rounded-left-0 {
  border-start-start-radius: 0 !important;
  border-end-start-radius: 0 !important;
}

.rounded-8 {
  border-radius: 8px !important;
}

.flex-1 {
  flex: 1 !important;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.scrollbar-hidden::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge add Firefox */
.scrollbar-hidden {
  -ms-overflow-style: none;
  scrollbar-width: none;
  /* Firefox */
}

/* RTL for pagination */
.page-item:first-child .page-link {
  margin-inline-start: 0;
  margin-inline-end: -1px;
  border-start-start-radius: 4px;
  border-end-start-radius: 4px;
  border-end-end-radius: 0;
  border-start-end-radius: 0;
}
.page-item:last-child .page-link {
  border-start-start-radius: 0;
  border-end-start-radius: 0;
  border-end-end-radius: 4px;
  border-start-end-radius: 4px;
}
/* END RTL for pagination */
