// #region inset
.top-0 {
  top: 0;
}
.right-0 {
  right: 0;
}
.bottom-0 {
  bottom: 0;
}
.left-0 {
  left: 0;
}

.inset-0 {
  @extend .top-0;
  @extend .right-0;
  @extend .bottom-0;
  @extend .left-0;
}
// #endregion

// #region z-index
.z-0 {
  z-index: 0;
}
.z-1000 {
  z-index: 1000;
}
.z-1010 {
  z-index: 1010;
}
.z-1020 {
  z-index: 1020;
}
.z-1030 {
  z-index: 1030;
}
.z-1040 {
  z-index: 1040;
}
.z-1050 {
  z-index: 1050;
}
.z-1060 {
  z-index: 1060;
}
// #endregion

.white-space-nowrap {
  white-space: nowrap;
}

// #region blur
.blur-none {
  filter: blur(0);
}
.blur-sm {
  filter: blur(4px);
}
.blur {
  filter: blur(8px);
}
.blur-md {
  filter: blur(12px);
}
.blur-lg {
  filter: blur(16px);
}
.blur-xl {
  filter: blur(24px);
}
.blur-2xl {
  filter: blur(40px);
}
.blur-3xl {
  filter: blur(64px);
}
// #endregion

// #region pointer-events
.pointer-events-none {
  pointer-events: none;
}
.pointer-events-auto {
  pointer-events: auto;
}
// #endregion

// #region cursor
.cursor-pointer {
  cursor: pointer;
}

.cursor-grab {
  cursor: grab;
}

.cursor-default {
  cursor: default !important;
}

.cursor-not-allowed {
  cursor: not-allowed !important;
}
// #endregion

.uppercase {
  text-transform: uppercase;
}

// #region borders radius
.rp-rounded-none {
  border-radius: 0;
}
.rp-rounded-sm {
  border-radius: 0.125rem;
}
.rp-rounded {
  border-radius: 0.25rem;
}
.rp-rounded-md {
  border-radius: 0.375rem;
}
.rp-rounded-lg {
  border-radius: 0.5rem;
}
.rp-rounded-xl {
  border-radius: 0.75rem;
}
.rp-rounded-2xl {
  border-radius: 1rem;
}
.rp-rounded-3xl {
  border-radius: 1.5rem;
}
// #endregion

// #region borders style
.border-solid {
  border-style: solid;
}
.\!border-solid {
  border-style: solid !important;
}
.border-dashed {
  border-style: dashed;
}
.\!border-dashed {
  border-style: dashed !important;
}
.border-dotted {
  border-style: dotted;
}
.\!border-dotted {
  border-style: dotted !important;
}
.border-double {
  border-style: double;
}
.\!border-double {
  border-style: double !important;
}
.border-hidden {
  border-style: hidden;
}
.\!border-hidden {
  border-style: hidden !important;
}
.border-none {
  border-style: none;
}
.\!border-none {
  border-style: none !important;
}
// #endregion

.custom-min-h {
  min-height: var(--custom-min-h);
}

.col-sticky {
  width: var(--col-width, 50px);
  min-width: var(--col-width, 50px);
  max-width: var(--col-width, 50px);
  left: var(--col-left, 0px);
}

// #region Object fit
.object-contain {
  object-fit: contain;
}
.object-cover {
  object-fit: cover;
}
.object-fill {
  object-fit: fill;
}
.object-none {
  object-fit: none;
}
.object-scale-down {
  object-fit: scale-down;
}
// #endregion
