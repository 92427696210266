@media (min-width: 576px) {
  .modal-dialog-scrollable {
    max-height: 100% !important;
  }
}

@media (min-width: 576px) and (max-width: 991px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px !important;
  }
}
