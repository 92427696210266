.react-tel-input .country-list > .search {
  padding: 10px 10px 6px 10px;
}

.react-tel-input .country-list > .search > .search-emoji {
  display: none;
}

.react-tel-input .country-list > .search > .search-box {
  margin-left: 0;
  width: 100%;
  padding: 8px;

  border: 1px solid #eff2f7;
  border-radius: 4px;
  color: #777f9e !important;
  height: 36px;
}
