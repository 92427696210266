// Variables

//
// custom-variables
//

// Vertical Sidebar - Default Light
$sidebar-bg: #ffffff;
$sidebar-menu-item-color: #545a6d;
$sidebar-menu-sub-item-color: #545a6d;
$sidebar-menu-item-icon-color: #7f8387;
$sidebar-menu-item-hover-color: #383c40;
$sidebar-menu-item-active-color: #114ef7;
$sidebar-width: 18.2vw;
$sidebar-collapsed-width: 60px;
$sidebar-width-sm: 160px;

// Vertical Sidebar - Dark
$sidebar-dark-bg: #e8e9eb; //2c313a
$sidebar-dark-menu-item-color: #0f1035;
$sidebar-dark-menu-sub-item-color: #0f1035;
$sidebar-dark-menu-item-icon-color: #6c6c72;
$sidebar-dark-menu-item-hover-color: #0f1035;
$sidebar-dark-menu-item-active-color: #0f1035;

// TopBar - Default Light

$header-height: 70px;
$header-bg: #ffffff;
$header-item-color: #555b6d;

// TopBar - Dark
$header-dark-bg: #2a3042;
$header-dark-item-color: #e9ecef;

// TopBar Search
$topbar-search-bg: #f3f3f9;

// Footer
$footer-height: 60px;
$footer-bg: #f2f2f5;
$footer-color: #74788d;

// Horizontal nav
$topnav-bg: #fff;

$menu-item-color: #545a6d;
$menu-item-active-color: #114ef7;

// Right Sidebar
$rightbar-width: 280px;

// Display
$display-none: none;
$display-block: block;

// Brand
$navbar-brand-box-width: 250px;

// Boxed layout width
$boxed-layout-width: 1300px;
$boxed-body-bg: #f4f5f7;

// Font Weight
$font-weight-medium: 500;
$font-weight-semibold: 600;

// apex charts
$apex-grid-color: #f8f9fa;

// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

// Color system

$white: #fff;
$gray-100: #f8f9fa;
$gray-200: #eff2f7;
$gray-300: #f6f6f6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #777f9e;
$gray-700: #0f1035;
$gray-800: #0f1035;
$gray-900: #212529;
$black: #000;
$gray-b: #e7e8f2;
$gray-bg: #f4f5f7;
$gray-h: #424652;
$zinc-700: #4a4e5b;

$primary-10: #fafbff;
$primary-20: #f0f4fe;
$primary-30: #e4ebfe;
$primary-40: #c8d6fd;
$primary-50: #adc2fc;
$primary-60: #91adfb;
$primary-70: #7598fa;
$primary-80: #5984f9;
$primary-90: #3e70f8;
$primary-100: #114ef7;
$primary-110: #073bca;
$primary-120: #052287;
$primary-130: #021143;

$secondary-10: #fcfcfd;
$secondary-20: #f7f7f8;
$secondary-30: #e8e9ea;
$secondary-40: #d0d1d4;
$secondary-80: #71747d;
$secondary-70: #898c93;
$secondary-60: #a1a3a9;
$secondary-50: #b9babf;
$secondary-90: #5a5d68;
$secondary-100: #424652;
$secondary-110: #31343d;
$secondary-120: #212329;
$secondary-130: #101114;

$text-white: #ffffff;
$text-10: #e7e7e7;
$text-20: #d2d2d2;
$text-30: #bdbdbd;
$text-40: #a8a8a8;
$text-50: #939393;
$text-60: #7e7e7e;
$text-70: #696969;
$text-80: #545454;
$text-90: #3f3f3f;
$text-100: #2a2a2a;
$text-110: #151515;
$text-black: #000000;

$surface-white: #ffffff;
$surface-10: #fcfcfd;
$surface-20: #f4f5f7;
$surface-30: #eeeff1;
$surface-40: #e0e3eb;
$surface-50: #d4d7e2;
$surface-60: #cbcedc;
$surface-70: #c2c6d6;
$surface-80: #b9bed0;
$surface-90: #b3b8cc;
$surface-100: #a9b0c6;
$surface-110: #a1a8bf;
$surface-120: #989fb9;

$gold-10: #fef9f6;
$gold-20: #fdfaee;
$gold-30: #fbf4d9;
$gold-40: #f8edc4;
$gold-50: #f6e7af;
$gold-60: #f3e09b;
$gold-70: #f1da86;
$gold-80: #eed371;
$gold-90: #eccd5c;
$gold-100: #e9c547;
$gold-110: #ad9025;
$gold-120: #736019;
$gold-130: #392f0c;

$slate-50: #f8fafc;
$slate-100: #f1f5f9;
$slate-200: #e2e8f0;
$slate-300: #cbd5e1;
$slate-400: #94a3b8;
$slate-500: #64748b;
$slate-600: #475569;
$slate-700: #334155;
$slate-800: #1e293b;
$slate-900: #0f172a;

$orange-50: #fff7ed;
$orange-100: #ffedd5;
$orange-200: #fed7aa;
$orange-300: #fdba74;
$orange-400: #fb923c;
$orange-500: #f97316;
$orange-600: #ea580c;
$orange-700: #c2410c;
$orange-800: #9a3412;
$orange-900: #7c2d12;

$indigo-50: #eef2ff;
$indigo-100: #e0e7ff;
$indigo-200: #c7d2fe;
$indigo-300: #a5b4fc;
$indigo-400: #818cf8;
$indigo-500: #6366f1;
$indigo-600: #4f46e5;
$indigo-700: #4338ca;
$indigo-800: #3730a3;
$indigo-900: #312e81;

$red-10: #fef6f6;
$red-20: #fce6e6;
$red-30: #f9d0d0;
$red-40: #f7b9b9;
$red-50: #f4a3a3;
$red-60: #f28c8c;
$red-70: #ef7676;
$red-80: #ec5f5f;
$red-90: #ea4949;
$red-100: #e73232;
$red-110: #b92828;
$red-120: #8b1e1e;
$red-130: #5c1414;

$blue-10: #f1f8fd;
$blue-20: #e6f2fc;
$blue-30: #d0e5f9;
$blue-40: #b9d9f7;
$blue-50: #a3cdf4;
$blue-60: #8cc1f2;
$blue-70: #76b5ef;
$blue-80: #5fa8ec;
$blue-90: #499cea;
$blue-100: #3290e7;
$blue-110: #256cad;
$blue-120: #194873;
$blue-130: #0c2439;

$yellow-10: #fffbee;
$yellow-20: #fff6d9;
$yellow-30: #fff2c3;
$yellow-40: #ffedae;
$yellow-50: #ffe899;
$yellow-60: #ffe384;
$yellow-70: #ffde6f;
$yellow-80: #ffda59;
$yellow-90: #ffd544;
$yellow-100: #ffd02f;
$yellow-110: #bf9c23;
$yellow-120: #7f6817;
$yellow-130: #3f330c;

$green-10: #edfbf1;
$green-20: #d6f6df;
$green-30: #bff0cd;
$green-40: #a9ebbb;
$green-50: #92e6aa;
$green-60: #7ce198;
$green-70: #65dc86;
$green-80: #4ed674;
$green-90: #38d163;
$green-100: #21cc51;
$green-110: #19993d;
$green-120: #106628;
$green-130: #083214;

$system-green-100: #106632;

$cyan-10: #fafefe;
$cyan-20: #f1fdfd;
$cyan-30: #e0fafb;
$cyan-40: #c0f5f8;
$cyan-50: #a0eff4;
$cyan-60: #80eaf1;
$cyan-70: #61e5ed;
$cyan-80: #41e0e9;
$cyan-90: #21dae6;
$cyan-100: #01d5e2;
$cyan-110: #01a0a9;
$cyan-120: #006a71;
$cyan-130: #003538;

$grays: ();
$grays: map-merge(
  (
    '100': $gray-100,
    '200': $gray-200,
    '300': $gray-300,
    '400': $gray-400,
    '500': $gray-500,
    '600': $gray-600,
    '700': $gray-700,
    '800': $gray-800,
    '900': $gray-900,
  ),
  $grays
);

$default: #0f1035;
$blue: #114ef7;
$rp-blue: #325ae7;

$indigo: #564ab1;
$purple: #6f42c1;
$pink: #e83e8c;
$red: #f64f59;
$orange: #f1734f;
$yellow: #ebbe21;
$green: #106632;
$green-light: #def7f0;
$teal: #050505;
$cyan: #50a5f1;

$colors: ();
$colors: map-merge(
  (
    'blue': $blue,
    'indigo': $indigo,
    'purple': $purple,
    'red': $red,
    'orange': $orange,
    'yellow': $yellow,
    'green': $green,
    'teal': $teal,
    'cyan': $cyan,
    'white': $white,
    'gray': $gray-600,
  ),
  $colors
);

$primary: $blue;

$secondary: $gray-600;
$success: $green;
$success-light: $green-light;
$info: $cyan;
$warning: $yellow;
$danger: $red;
$light: $gray-200;
$dark: $gray-800;

$theme-colors: ();
$theme-colors: map-merge(
  (
    'default': $default,
    'primary': $primary,
    'secondary': $secondary,
    'success': $success,
    'success-light': $success-light,
    'info': $info,
    'warning': $warning,
    'danger': $danger,
    'light': $light,
    'dark': $dark,
    'gray-b': $gray-b,
    'gray-bg': $gray-bg,
    'gray-h': $gray-h,
    'zinc-700': $zinc-700,
    'gray-100': $gray-100,
    'gray-200': $gray-200,
    'gray-300': $gray-300,
    'gray-400': $gray-400,
    'gray-500': $gray-500,
    'gray-600': $gray-600,
    'gray-700': $gray-700,
    'gray-800': $gray-800,
    'gray-900': $gray-900,
    'primary-10': $primary-10,
    'primary-20': $primary-20,
    'primary-30': $primary-30,
    'primary-40': $primary-40,
    'primary-50': $primary-50,
    'primary-60': $primary-60,
    'primary-70': $primary-70,
    'primary-80': $primary-80,
    'primary-90': $primary-90,
    'primary-100': $primary-100,
    'primary-110': $primary-110,
    'primary-120': $primary-120,
    'primary-130': $primary-130,
    'secondary-10': $secondary-10,
    'secondary-20': $secondary-20,
    'secondary-30': $secondary-30,
    'secondary-40': $secondary-40,
    'secondary-80': $secondary-80,
    'secondary-70': $secondary-70,
    'secondary-60': $secondary-60,
    'secondary-50': $secondary-50,
    'secondary-90': $secondary-90,
    'secondary-100': $secondary-100,
    'secondary-110': $secondary-110,
    'secondary-120': $secondary-120,
    'secondary-130': $secondary-130,
    'text-white': $text-white,
    'text-10': $text-10,
    'text-20': $text-20,
    'text-30': $text-30,
    'text-40': $text-40,
    'text-50': $text-50,
    'text-60': $text-60,
    'text-70': $text-70,
    'text-80': $text-80,
    'text-90': $text-90,
    'text-100': $text-100,
    'text-110': $text-110,
    'text-black': $text-black,
    'surface-white': $surface-white,
    'surface-10': $surface-10,
    'surface-20': $surface-20,
    'surface-30': $surface-30,
    'surface-40': $surface-40,
    'surface-50': $surface-50,
    'surface-60': $surface-60,
    'surface-70': $surface-70,
    'surface-80': $surface-80,
    'surface-90': $surface-90,
    'surface-100': $surface-100,
    'surface-110': $surface-110,
    'surface-120': $surface-120,
    'gold-10': $gold-10,
    'gold-20': $gold-20,
    'gold-30': $gold-30,
    'gold-40': $gold-40,
    'gold-50': $gold-50,
    'gold-60': $gold-60,
    'gold-70': $gold-70,
    'gold-80': $gold-80,
    'gold-90': $gold-90,
    'gold-100': $gold-100,
    'gold-110': $gold-110,
    'gold-120': $gold-120,
    'gold-130': $gold-130,
    'red-10': $red-10,
    'red-20': $red-20,
    'red-30': $red-30,
    'red-40': $red-40,
    'red-50': $red-50,
    'red-60': $red-60,
    'red-70': $red-70,
    'red-80': $red-80,
    'red-90': $red-90,
    'red-100': $red-100,
    'red-110': $red-110,
    'red-120': $red-120,
    'red-130': $red-130,
    'blue-10': $blue-10,
    'blue-20': $blue-20,
    'blue-30': $blue-30,
    'blue-40': $blue-40,
    'blue-50': $blue-50,
    'blue-60': $blue-60,
    'blue-70': $blue-70,
    'blue-80': $blue-80,
    'blue-90': $blue-90,
    'blue-100': $blue-100,
    'blue-110': $blue-110,
    'blue-120': $blue-120,
    'blue-130': $blue-130,
    'yellow-10': $yellow-10,
    'yellow-20': $yellow-20,
    'yellow-30': $yellow-30,
    'yellow-40': $yellow-40,
    'yellow-50': $yellow-50,
    'yellow-60': $yellow-60,
    'yellow-70': $yellow-70,
    'yellow-80': $yellow-80,
    'yellow-90': $yellow-90,
    'yellow-100': $yellow-100,
    'yellow-110': $yellow-110,
    'yellow-120': $yellow-120,
    'yellow-130': $yellow-130,
    'green-10': $green-10,
    'green-20': $green-20,
    'green-30': $green-30,
    'green-40': $green-40,
    'green-50': $green-50,
    'green-60': $green-60,
    'green-70': $green-70,
    'green-80': $green-80,
    'green-90': $green-90,
    'green-100': $green-100,
    'green-110': $green-110,
    'green-120': $green-120,
    'green-130': $green-130,
    'system-green-100': $system-green-100,
    'cyan-10': $cyan-10,
    'cyan-20': $cyan-20,
    'cyan-30': $cyan-30,
    'cyan-40': $cyan-40,
    'cyan-50': $cyan-50,
    'cyan-60': $cyan-60,
    'cyan-70': $cyan-70,
    'cyan-80': $cyan-80,
    'cyan-90': $cyan-90,
    'cyan-100': $cyan-100,
    'cyan-110': $cyan-110,
    'cyan-120': $cyan-120,
    'cyan-130': $cyan-130,
    'slate-50': $slate-50,
    'slate-100': $slate-100,
    'slate-200': $slate-200,
    'slate-300': $slate-300,
    'slate-400': $slate-400,
    'slate-500': $slate-500,
    'slate-600': $slate-600,
    'slate-700': $slate-700,
    'slate-800': $slate-800,
    'slate-900': $slate-900,
    'orange-50': $orange-50,
    'orange-100': $orange-100,
    'orange-200': $orange-200,
    'orange-300': $orange-300,
    'orange-400': $orange-400,
    'orange-500': $orange-500,
    'orange-600': $orange-600,
    'orange-700': $orange-700,
    'orange-800': $orange-800,
    'orange-900': $orange-900,
    'indigo-50': $indigo-50,
    'indigo-100': $indigo-100,
    'indigo-200': $indigo-200,
    'indigo-300': $indigo-300,
    'indigo-400': $indigo-400,
    'indigo-500': $indigo-500,
    'indigo-600': $indigo-600,
    'indigo-700': $indigo-700,
    'indigo-800': $indigo-800,
    'indigo-900': $indigo-900,
  ),
  $theme-colors
);

// Set a specific jump point for requesting color jumps
$theme-color-interval: 8%;

// The yiq lightness value that determines when the lightness of color changes from "dark" to "light". Acceptable values are between 0 and 255.
$yiq-contrasted-threshold: 200;

// Customize the light and dark text colors for use in our YIQ color contrast function.
$yiq-text-dark: $gray-900;
$yiq-text-light: $white;

// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-caret: false;
$enable-rounded: true;
$enable-shadows: false;
$enable-gradients: false;
$enable-transitions: true;
$enable-prefers-reduced-motion-media-query: true;
$enable-hover-media-query: false; // Deprecated, no longer affects any compiled CSS
$enable-grid-classes: true;
$enable-pointer-cursor-for-buttons: true;
$enable-print-styles: true;
$enable-responsive-font-sizes: false;
$enable-validation-icons: true;
$enable-deprecation-messages: true;

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

$spacer: 1rem;
$spacers: ();
$spacers: map-merge(
  (
    0: 0,
    1: (
      $spacer * 0.25,
    ),
    2: (
      $spacer * 0.5,
    ),
    3: $spacer,
    4: (
      $spacer * 1.5,
    ),
    5: (
      $spacer * 3,
    ),
  ),
  $spacers
);

// This variable affects the `.h-*` and `.w-*` classes.
$sizes: map-merge(
  (
    0: 0,
    25: 25%,
    50: 50%,
    75: 75%,
    100: 100%,
    auto: auto,
  ),
  $sizes
);

// Body
//
// Settings for the `<body>` element.

// $body-bg: #f4f5f7;
$body-bg: $gray-bg;
$body-color: $gray-700;

// Links
//
// Style anchor elements.

$link-color: theme-color('primary');
$link-decoration: none;
$link-hover-color: darken($link-color, 15%);
$link-hover-decoration: underline;
// Darken percentage for links with `.text-*` class (e.g. `.text-success`)
$emphasized-link-hover-darken-percentage: 15%;

// Paragraphs
//
// Style p element.

$paragraph-margin-bottom: 1rem;

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
);

@include _assert-ascending($grid-breakpoints, '$grid-breakpoints');
@include _assert-starts-at-zero($grid-breakpoints, '$grid-breakpoints');

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
);

@include _assert-ascending($container-max-widths, '$container-max-widths');

// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns: 12;
$grid-gutter-width: 48px;

// Components
//
// Define common padding and border radius sizes and more.

$line-height-lg: 1.5;
$line-height-sm: 1.5;

$border-width: 1px;
$border-color: $gray-200;

$border-radius: 4px;
$border-radius-lg: 0.7rem;
$border-radius-sm: 0.1rem;

$rounded-pill: 50rem;

$box-shadow-sm: 0 0.125rem 0.25rem rgba($black, 0.075);
$box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
$box-shadow-lg: 0 1rem 3rem rgba($black, 0.175);

$component-active-bg: $primary;

$caret-width: 0.3em;
$caret-vertical-align: $caret-width * 0.85;
$caret-spacing: $caret-width * 0.85;

$transition-base: all 0.2s ease-in-out;
$transition-fade: opacity 0.15s linear;
$transition-collapse: height 0.35s ease;

$embed-responsive-aspect-ratios: ();
$embed-responsive-aspect-ratios: join(
  ((21 9), (16 9), (4 3), (1 1)),
  $embed-responsive-aspect-ratios
);

// Typography
//
// Font, line-height, and color for body text, headings, and more.

$font-family-sans-serif:
  'Mulish',
  'Noto Kufi Arabic',
  inter,
  -apple-system,
  BlinkMacSystemFont,
  Segoe UI,
  Roboto,
  Helvetica Neue,
  Ubuntu,
  sans-serif;
$font-family-monospace:
  SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New',
  monospace;
$font-family-base: $font-family-sans-serif;

$font-size-base: 0.875rem;
$font-size-lg: $font-size-base * 1.25;
$font-size-sm: $font-size-base * 0.875;

$font-weight-lighter: lighter;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-bold: 600;
$font-weight-bolder: bolder;

$font-weight-base: $font-weight-normal;
$line-height-base: 1.5;

$h1-font-size: $font-size-base * 2.5;
$h2-font-size: $font-size-base * 2;
$h3-font-size: $font-size-base * 1.75;
$h4-font-size: $font-size-base * 1.5;
$h5-font-size: $font-size-base * 1.25;
$h6-font-size: $font-size-base;

$headings-margin-bottom: calc($spacer / 2);
$headings-font-family: null;
$headings-font-weight: 500;
$headings-line-height: 1.2;
$headings-color: null;

$display1-size: 6rem;
$display2-size: 5.5rem;
$display3-size: 4.5rem;
$display4-size: 3.5rem;

$display1-weight: 300;
$display2-weight: 300;
$display3-weight: 300;
$display4-weight: 300;
$display-line-height: $headings-line-height;

$lead-font-size: $font-size-base * 1.25;
$lead-font-weight: 300;

$small-font-size: 80%;

$text-muted: $gray-600;

$blockquote-small-color: $gray-600;
$blockquote-small-font-size: $small-font-size;
$blockquote-font-size: $font-size-base * 1.25;

$hr-border-color: rgba($black, 0.1);
$hr-border-width: $border-width;

$mark-padding: 0.2em;

$dt-font-weight: $font-weight-bold;

$kbd-box-shadow: inset 0 -0.1rem 0 rgba($black, 0.25);
$nested-kbd-font-weight: $font-weight-bold;

$list-inline-padding: 0.5rem;

$mark-bg: #fcf8e3;

$hr-margin-y: $spacer;

// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.

$table-cell-padding: 0.75rem;
$table-cell-padding-sm: 0.3rem;

$table-color: $body-color;
$table-bg: null;
$table-accent-bg: $gray-100;
$table-hover-color: $table-color;
$table-hover-bg: $gray-100;
$table-active-bg: $table-hover-bg;

$table-border-width: $border-width;
$table-border-color: $border-color;

$table-head-bg: $white;
$table-head-color: $gray-700;

$table-dark-color: $white;
$table-dark-bg: $gray-800;
$table-dark-accent-bg: rgba($white, 0.05);
$table-dark-hover-color: $table-dark-color;
$table-dark-hover-bg: rgba($white, 0.075);
$table-dark-border-color: lighten($table-dark-bg, 7.5%);
$table-dark-color: $white;

$table-striped-order: odd;

$table-caption-color: $text-muted;

$table-bg-level: -9;
$table-border-level: -6;

// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.

$input-btn-padding-y: 0.65rem;
$input-btn-padding-x: 0.75rem;
$input-btn-font-family: inherit;
$input-btn-font-size: $font-size-base;
$input-btn-line-height: $line-height-base;

$input-btn-focus-width: 0.15rem;
$input-btn-focus-color: rgba($component-active-bg, 0.25);
$input-btn-focus-box-shadow: 0 0 0 $input-btn-focus-width $input-btn-focus-color;

$input-btn-padding-y-sm: 0.25rem;
$input-btn-padding-x-sm: 0.5rem;
$input-btn-font-size-sm: $font-size-sm;
$input-btn-line-height-sm: $line-height-sm;

$input-btn-padding-y-lg: 0.5rem;
$input-btn-padding-x-lg: 1rem;
$input-btn-font-size-lg: $font-size-lg;
$input-btn-line-height-lg: $line-height-lg;

$input-btn-border-width: $border-width;

// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.

$btn-padding-y: $input-btn-padding-y;
$btn-padding-x: 1.75rem;
$btn-font-family: $input-btn-font-family;
$btn-font-size: $input-btn-font-size;
$btn-line-height: $input-btn-line-height;

$btn-padding-y-sm: $input-btn-padding-y-sm;
$btn-padding-x-sm: $input-btn-padding-x-sm;
$btn-font-size-sm: $input-btn-font-size-sm;
$btn-line-height-sm: $input-btn-line-height-sm;

$btn-padding-y-lg: $input-btn-padding-y-lg;
$btn-padding-x-lg: $input-btn-padding-x-lg;
$btn-font-size-lg: $input-btn-font-size-lg;
$btn-line-height-lg: $input-btn-line-height-lg;

$btn-border-width: $input-btn-border-width;

$btn-font-weight: $font-weight-semibold;
$btn-box-shadow:
  inset 0 1px 0 rgba($white, 0.15),
  0 1px 1px rgba($black, 0.075);
$btn-focus-width: $input-btn-focus-width;
$btn-focus-box-shadow: $input-btn-focus-box-shadow;
$btn-disabled-opacity: 0.65;
$btn-active-box-shadow: inset 0 3px 5px rgba($black, 0.125);

$btn-link-disabled-color: $gray-600;

$btn-block-spacing-y: 0.5rem;

// Allows for customizing button radius independently from global border radius
$btn-border-radius: $border-radius;
$btn-border-radius-lg: $border-radius-lg;
$btn-border-radius-sm: $border-radius-sm;

$btn-transition:
  color 0.15s ease-in-out,
  background-color 0.15s ease-in-out,
  border-color 0.15s ease-in-out,
  box-shadow 0.15s ease-in-out;

// Forms

$label-margin-bottom: 0.5rem;

$input-padding-y: $input-btn-padding-y;
$input-padding-x: $input-btn-padding-x;
$input-font-family: $input-btn-font-family;
$input-font-size: $input-btn-font-size;
$input-font-weight: $font-weight-base;
$input-line-height: $input-btn-line-height;

$input-padding-y-sm: $input-btn-padding-y-sm;
$input-padding-x-sm: $input-btn-padding-x-sm;
$input-font-size-sm: $input-btn-font-size-sm;
$input-line-height-sm: $input-btn-line-height-sm;

$input-padding-y-lg: $input-btn-padding-y-lg;
$input-padding-x-lg: $input-btn-padding-x-lg;
$input-font-size-lg: $input-btn-font-size-lg;
$input-line-height-lg: $input-btn-line-height-lg;

$input-bg: $white;
$input-disabled-bg: $white;

$input-color: $gray-600;
$input-border-color: $gray-b;
$input-border-width: $input-btn-border-width;
$input-box-shadow: inset 0 1px 1px rgba($black, 0.075);

$input-border-radius: $border-radius;
$input-border-radius-lg: $border-radius-lg;
$input-border-radius-sm: $border-radius-sm;

$input-focus-bg: $input-bg;
$input-focus-border-color: darken($input-border-color, 10%);
$input-focus-color: $input-color;
$input-focus-width: $input-btn-focus-width;
$input-focus-box-shadow: none;

$input-placeholder-color: $gray-500;
$input-plaintext-color: $body-color;

$input-height-border: $input-border-width * 2;

$input-height-inner: calc(
  #{$input-line-height * 1em} + #{$input-padding-y * 2}
);
$input-height-inner-half: calc(
  #{$input-line-height * 0.5em} + #{$input-padding-y}
);
$input-height-inner-quarter: calc(
  #{$input-line-height * 0.25em} + #{calc($input-padding-y / 2)}
);

$input-height: calc(
  #{$input-line-height * 1em} + #{$input-padding-y * 2} +
    #{$input-height-border}
);
$input-height-sm: calc(
  #{$input-line-height-sm * 1em} + #{$input-btn-padding-y-sm * 2} +
    #{$input-height-border}
);
$input-height-lg: calc(
  #{$input-line-height-lg * 1.5em} + #{$input-btn-padding-y-lg * 2} +
    #{$input-height-border}
);

$input-transition:
  border-color 0.15s ease-in-out,
  box-shadow 0.15s ease-in-out;

$form-text-margin-top: 0.25rem;

$form-check-input-gutter: 1.25rem;
$form-check-input-margin-y: 0.3rem;
$form-check-input-margin-x: 0.25rem;

$form-check-inline-margin-x: 0.75rem;
$form-check-inline-input-margin-x: 0.3125rem;

$form-grid-gutter-width: 10px;
$form-group-margin-bottom: 1rem;

$input-group-addon-color: $input-color;
$input-group-addon-bg: #fff;
$input-group-addon-border-color: $input-border-color;

$custom-forms-transition:
  background-color 0.15s ease-in-out,
  border-color 0.15s ease-in-out,
  box-shadow 0.15s ease-in-out;

$custom-control-gutter: 0.5rem;
$custom-control-spacer-x: 1rem;

$custom-control-indicator-size: 1rem;
$custom-control-indicator-bg: $input-bg;

$custom-control-indicator-bg-size: 50% 50%;
$custom-control-indicator-box-shadow: $input-box-shadow;
$custom-control-indicator-border-color: $gray-500;
$custom-control-indicator-border-width: $input-border-width;

$custom-control-indicator-disabled-bg: $input-disabled-bg;
$custom-control-label-disabled-color: $gray-600;

$custom-control-indicator-checked-color: $component-active-color;
$custom-control-indicator-checked-bg: $primary;
$custom-control-indicator-checked-disabled-bg: rgba(
  theme-color('primary'),
  0.5
);
$custom-control-indicator-checked-box-shadow: none;
$custom-control-indicator-checked-border-color: $custom-control-indicator-checked-bg;

$custom-control-indicator-focus-box-shadow: $input-focus-box-shadow;
$custom-control-indicator-focus-border-color: $input-focus-border-color;

$custom-control-indicator-active-color: $component-active-color;
$custom-control-indicator-active-bg: lighten($component-active-bg, 35%);
$custom-control-indicator-active-box-shadow: none;
$custom-control-indicator-active-border-color: $custom-control-indicator-active-bg;

$custom-checkbox-indicator-border-radius: $border-radius;
$custom-checkbox-indicator-icon-checked: str-replace(
  url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='#{$custom-control-indicator-checked-color}' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e"),
  '#',
  '%23'
);

$custom-checkbox-indicator-indeterminate-bg: $component-active-bg;
$custom-checkbox-indicator-indeterminate-color: $custom-control-indicator-checked-color;
$custom-checkbox-indicator-icon-indeterminate: str-replace(
  url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3e%3cpath stroke='#{$custom-checkbox-indicator-indeterminate-color}' d='M0 2h4'/%3e%3c/svg%3e"),
  '#',
  '%23'
);
$custom-checkbox-indicator-indeterminate-box-shadow: none;
$custom-checkbox-indicator-indeterminate-border-color: $custom-checkbox-indicator-indeterminate-bg;

$custom-radio-indicator-border-radius: 50%;
$custom-radio-indicator-icon-checked: str-replace(
  url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='#{$custom-control-indicator-checked-color}'/%3e%3c/svg%3e"),
  '#',
  '%23'
);

$custom-switch-width: $custom-control-indicator-size * 1.75;
$custom-switch-indicator-border-radius: calc(
  $custom-control-indicator-size / 2
);
$custom-switch-indicator-size: calc(
  #{$custom-control-indicator-size} - #{$custom-control-indicator-border-width *
    4}
);

$custom-select-padding-y: $input-padding-y;
$custom-select-padding-x: $input-padding-x;
$custom-select-font-family: $input-font-family;
$custom-select-font-size: $input-font-size;
$custom-select-height: $input-height;
$custom-select-indicator-padding: 1rem; // Extra padding to account for the presence of the background-image based indicator
$custom-select-font-weight: $input-font-weight;
$custom-select-line-height: $input-line-height;
$custom-select-color: $input-color;
$custom-select-disabled-color: $gray-600;
$custom-select-bg: $input-bg;
$custom-select-disabled-bg: $gray-200;
$custom-select-bg-size: 8px 10px; // In pixels because image dimensions
$custom-select-indicator-color: $gray-800;
$custom-select-indicator: str-replace(
  url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='#{$custom-select-indicator-color}' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e"),
  '#',
  '%23'
);
$custom-select-background: $custom-select-indicator no-repeat right
  $custom-select-padding-x center / $custom-select-bg-size; // Used so we can have multiple background elements (e.g., arrow and feedback icon)

$custom-select-feedback-icon-padding-right: calc(
  (1em + #{2 * $custom-select-padding-y}) * 3 / 4 + #{$custom-select-padding-x +
    $custom-select-indicator-padding}
);
$custom-select-feedback-icon-position: center right
  ($custom-select-padding-x + $custom-select-indicator-padding);
$custom-select-feedback-icon-size: $input-height-inner-half
  $input-height-inner-half;

$custom-select-border-width: $input-border-width;
$custom-select-border-color: $input-border-color;
$custom-select-border-radius: $border-radius;
$custom-select-box-shadow: inset 0 1px 2px rgba($black, 0.075);

$custom-select-focus-border-color: $input-focus-border-color;
$custom-select-focus-width: $input-focus-width;
$custom-select-focus-box-shadow: 0 0 0 $custom-select-focus-width
  $input-btn-focus-color;

$custom-select-padding-y-sm: $input-padding-y-sm;
$custom-select-padding-x-sm: $input-padding-x-sm;
$custom-select-font-size-sm: $input-font-size-sm;
$custom-select-height-sm: $input-height-sm;

$custom-select-padding-y-lg: $input-padding-y-lg;
$custom-select-padding-x-lg: $input-padding-x-lg;
$custom-select-font-size-lg: $input-font-size-lg;
$custom-select-height-lg: $input-height-lg;

$custom-range-track-width: 100%;
$custom-range-track-height: 0.5rem;
$custom-range-track-cursor: pointer;
$custom-range-track-bg: $gray-300;
$custom-range-track-border-radius: 1rem;
$custom-range-track-box-shadow: inset 0 0.25rem 0.25rem rgba($black, 0.1);

$custom-range-thumb-width: 1rem;
$custom-range-thumb-height: $custom-range-thumb-width;
$custom-range-thumb-bg: $component-active-bg;
$custom-range-thumb-border: 0;
$custom-range-thumb-border-radius: 1rem;
$custom-range-thumb-box-shadow: 0 0.1rem 0.25rem rgba($black, 0.1);
$custom-range-thumb-focus-box-shadow:
  0 0 0 1px $body-bg,
  $input-focus-box-shadow;
$custom-range-thumb-focus-box-shadow-width: $input-focus-width; // For focus box shadow issue in IE/Edge
$custom-range-thumb-active-bg: lighten($component-active-bg, 35%);
$custom-range-thumb-disabled-bg: $gray-500;

$custom-file-height: $input-height;
$custom-file-height-inner: $input-height-inner;
$custom-file-focus-border-color: $input-focus-border-color;
$custom-file-focus-box-shadow: $input-focus-box-shadow;
$custom-file-disabled-bg: $input-disabled-bg;

$custom-file-padding-y: $input-padding-y;
$custom-file-padding-x: $input-padding-x;
$custom-file-line-height: $input-line-height;
$custom-file-font-family: $input-font-family;
$custom-file-font-weight: $input-font-weight;
$custom-file-color: $input-color;
$custom-file-bg: $input-bg;
$custom-file-border-width: $input-border-width;
$custom-file-border-color: $input-border-color;
$custom-file-border-radius: $input-border-radius;
$custom-file-box-shadow: $input-box-shadow;
$custom-file-button-color: $custom-file-color;
$custom-file-button-bg: $input-group-addon-bg;
$custom-file-text: (
  en: 'Browse',
);

// Form validation

$form-feedback-margin-top: $form-text-margin-top;
$form-feedback-font-size: $small-font-size;
$form-feedback-valid-color: theme-color('success');
$form-feedback-invalid-color: theme-color('danger');

$form-feedback-icon-valid-color: $form-feedback-valid-color;
$form-feedback-icon-valid: str-replace(
  url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='#{$form-feedback-icon-valid-color}' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e"),
  '#',
  '%23'
);
$form-feedback-icon-invalid-color: $form-feedback-invalid-color;
$form-feedback-icon-invalid: str-replace(
  url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='#{$form-feedback-icon-invalid-color}' viewBox='-2 -2 7 7'%3e%3cpath stroke='#{$form-feedback-icon-invalid-color}' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E"),
  '#',
  '%23'
);

$form-validation-states: ();
$form-validation-states: map-merge(
  (
    'valid': (
      'color': $form-feedback-valid-color,
      'icon': $form-feedback-icon-valid,
    ),
    'invalid': (
      'color': $form-feedback-invalid-color,
      'icon': $form-feedback-icon-invalid,
    ),
  ),
  $form-validation-states
);

// Z-index master list
//
// Warning: Avoid customizing these values. They're used for a bird's eye view
// of components dependent on the z-axis and are designed to all work together.

$zindex-dropdown: 1000;
$zindex-sticky: 1020;
$zindex-fixed: 1030;
$zindex-modal-backdrop: 1040;
$zindex-modal: 1050;
$zindex-popover: 1060;
$zindex-tooltip: 1070;

// Nav links

$nav-link-padding-y: 0.5rem;
$nav-link-padding-x: 1rem;
$nav-link-disabled-color: $gray-600;

$nav-tabs-border-color: $gray-400;
$nav-tabs-border-width: $border-width;
$nav-tabs-border-radius: $border-radius;
$nav-tabs-link-hover-border-color: $gray-200 $gray-200 $nav-tabs-border-color;
$nav-tabs-link-active-color: $gray-700;
$nav-tabs-link-active-bg: $card-bg;
$nav-tabs-link-active-border-color: $gray-400 $gray-400 $nav-tabs-link-active-bg;

$nav-pills-border-radius: $border-radius;
$nav-pills-link-active-color: $component-active-color;
$nav-pills-link-active-bg: $component-active-bg;

$nav-divider-color: $gray-200;
$nav-divider-margin-y: calc($spacer / 2);

// Navbar

$navbar-padding-y: calc($spacer / 2);
$navbar-padding-x: $spacer;

$navbar-nav-link-padding-x: 0.5rem;

$navbar-brand-font-size: $font-size-lg;
// Compute the navbar-brand padding-y so the navbar-brand will have the same height as navbar-text and nav-link
$nav-link-height: $font-size-base * $line-height-base + $nav-link-padding-y * 2;
$navbar-brand-height: $navbar-brand-font-size * $line-height-base;
$navbar-brand-padding-y: calc(($nav-link-height - $navbar-brand-height) / 2);

$navbar-toggler-padding-y: 0.25rem;
$navbar-toggler-padding-x: 0.75rem;
$navbar-toggler-font-size: $font-size-lg;
$navbar-toggler-border-radius: $btn-border-radius;

$navbar-dark-color: rgba($white, 0.5);
$navbar-dark-hover-color: rgba($white, 0.75);
$navbar-dark-active-color: $white;
$navbar-dark-disabled-color: rgba($white, 0.25);
$navbar-dark-toggler-icon-bg: str-replace(
  url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='#{$navbar-dark-color}' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"),
  '#',
  '%23'
);
$navbar-dark-toggler-border-color: rgba($white, 0.1);

$navbar-light-color: rgba($black, 0.5);
$navbar-light-hover-color: rgba($black, 0.7);
$navbar-light-active-color: rgba($black, 0.9);
$navbar-light-disabled-color: rgba($black, 0.3);
$navbar-light-toggler-icon-bg: str-replace(
  url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='#{$navbar-light-color}' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"),
  '#',
  '%23'
);
$navbar-light-toggler-border-color: rgba($black, 0.1);

$navbar-light-brand-color: $navbar-light-active-color;
$navbar-light-brand-hover-color: $navbar-light-active-color;
$navbar-dark-brand-color: $navbar-dark-active-color;
$navbar-dark-brand-hover-color: $navbar-dark-active-color;

// Dropdowns
//
// Dropdown menu container and contents.

$dropdown-min-width: 10rem;
$dropdown-padding-y: 0.5rem;
$dropdown-spacer: 0.125rem;
$dropdown-font-size: $font-size-base;
$dropdown-color: $body-color;
$dropdown-bg: $white;
$dropdown-border-color: rgba($black, 0.15);
$dropdown-border-radius: $border-radius;
$dropdown-border-width: 0;
$dropdown-inner-border-radius: calc(
  #{$dropdown-border-radius} - #{$dropdown-border-width}
);
$dropdown-divider-bg: $gray-200;
$dropdown-divider-margin-y: $nav-divider-margin-y;
$dropdown-box-shadow: 0 0.5rem 1rem rgba($black, 0.175);

$dropdown-link-color: $gray-900;
$dropdown-link-hover-color: darken($gray-900, 5%);
$dropdown-link-hover-bg: $gray-100;

$dropdown-link-active-color: darken($gray-900, 5%);
$dropdown-link-active-bg: $gray-100;

$dropdown-link-disabled-color: $gray-600;

$dropdown-item-padding-y: 0.35rem;
$dropdown-item-padding-x: 1.5rem;

$dropdown-header-color: $gray-600;

// Pagination

$pagination-padding-y: 0.5rem;
$pagination-padding-x: 0.75rem;
$pagination-padding-y-sm: 0.25rem;
$pagination-padding-x-sm: 0.5rem;
$pagination-padding-y-lg: 0.75rem;
$pagination-padding-x-lg: 1.5rem;
$pagination-line-height: 1.25;

$pagination-color: $gray-600;
$pagination-bg: $white;
$pagination-border-width: $border-width;
$pagination-border-color: $gray-400;

$pagination-focus-box-shadow: $input-btn-focus-box-shadow;
$pagination-focus-outline: 0;

$pagination-hover-color: $link-hover-color;
$pagination-hover-bg: $gray-200;
$pagination-hover-border-color: $gray-400;

$pagination-active-color: $component-active-color;
$pagination-active-bg: $component-active-bg;
$pagination-active-border-color: $pagination-active-bg;

$pagination-disabled-color: $gray-400;
$pagination-disabled-bg: $white;
$pagination-disabled-border-color: $gray-400;

// Jumbotron

$jumbotron-padding: 2rem;
$jumbotron-color: null;
$jumbotron-bg: $gray-200;

// Cards

$card-spacer-y: 0.75rem;
$card-spacer-x: 1.25rem;
$card-border-width: 0;
$card-border-radius: $border-radius;
$card-border-color: $gray-300;
$card-inner-border-radius: calc(#{$card-border-radius} - #{$card-border-width});
$card-cap-bg: $gray-300;
$card-cap-color: null;
$card-color: null;
$card-bg: $white;

$card-title-desc: $gray-600;

$card-img-overlay-padding: 1.25rem;

$card-group-margin: calc($grid-gutter-width / 2);
$card-deck-margin: $card-group-margin;

$card-columns-count: 3;
$card-columns-gap: 1.25rem;
$card-columns-margin: $grid-gutter-width;

// Tooltips

$tooltip-font-size: $font-size-sm;
$tooltip-max-width: 200px;
$tooltip-color: $white;
$tooltip-bg: $black;
$tooltip-border-radius: $border-radius;
$tooltip-opacity: 0.9;
$tooltip-padding-y: 0.4rem;
$tooltip-padding-x: 0.7rem;
$tooltip-margin: 0;

$tooltip-arrow-width: 0.8rem;
$tooltip-arrow-height: 0.4rem;
$tooltip-arrow-color: $tooltip-bg;

// Form tooltips must come after regular tooltips
$form-feedback-tooltip-padding-y: $tooltip-padding-y;
$form-feedback-tooltip-padding-x: $tooltip-padding-x;
$form-feedback-tooltip-font-size: $tooltip-font-size;
$form-feedback-tooltip-line-height: $line-height-base;
$form-feedback-tooltip-opacity: $tooltip-opacity;
$form-feedback-tooltip-border-radius: $tooltip-border-radius;

// Popovers

$popover-font-size: $font-size-sm;
$popover-bg: $white;
$popover-max-width: 276px;
$popover-border-width: $border-width;
$popover-border-color: $gray-200;
$popover-border-radius: $border-radius-lg;
$popover-box-shadow: 0 0.25rem 0.5rem rgba($black, 0.2);

$popover-header-bg: darken($popover-bg, 3%);
$popover-header-color: $headings-color;
$popover-header-padding-y: 0.5rem;
$popover-header-padding-x: 0.75rem;

$popover-body-color: $body-color;
$popover-body-padding-y: $popover-header-padding-y;
$popover-body-padding-x: $popover-header-padding-x;

$popover-arrow-width: 1rem;
$popover-arrow-height: 0.5rem;
$popover-arrow-color: $popover-bg;

$popover-arrow-outer-color: fade-in($popover-border-color, 0.05);

// Toasts

$toast-max-width: 350px;
$toast-padding-x: 0.75rem;
$toast-padding-y: 0.25rem;
$toast-font-size: 0.875rem;
$toast-color: null;
$toast-background-color: rgba($white, 0.85);
$toast-border-width: 1px;
$toast-border-color: rgba(0, 0, 0, 0.1);
$toast-border-radius: 0.25rem;
$toast-box-shadow: 0 0.25rem 0.75rem rgba($black, 0.1);

$toast-header-color: $gray-600;
$toast-header-background-color: rgba($white, 0.85);
$toast-header-border-color: rgba(0, 0, 0, 0.05);

// Badges

$badge-font-size: 75%;
$badge-font-weight: $font-weight-medium;
$badge-padding-y: 0.25em;
$badge-padding-x: 0.4em;
$badge-border-radius: $border-radius;

$badge-transition: $btn-transition;
$badge-focus-width: $input-btn-focus-width;

$badge-pill-padding-x: 0.6em;
// Use a higher than normal value to ensure completely rounded edges when
// customizing padding or font-size on labels.
$badge-pill-border-radius: 10rem;

// Modals

// Padding applied to the modal body
$modal-inner-padding: 1rem;

$modal-dialog-margin: 0.5rem;
$modal-dialog-margin-y-sm-up: 1.75rem;

$modal-title-line-height: $line-height-base;

$modal-content-color: null;
$modal-content-bg: $white;
$modal-content-border-color: $gray-300;
$modal-content-border-width: $border-width;
$modal-content-border-radius: $border-radius;
$modal-content-box-shadow-xs: 0 0.25rem 0.5rem rgba($black, 0.5);
$modal-content-box-shadow-sm-up: 0 0.5rem 1rem rgba($black, 0.5);

$modal-backdrop-bg: $black;
$modal-backdrop-opacity: 0.5;
$modal-header-border-color: $border-color;
$modal-footer-border-color: $modal-header-border-color;
$modal-header-border-width: $modal-content-border-width;
$modal-footer-border-width: $modal-header-border-width;
$modal-header-padding-y: 1rem;
$modal-header-padding-x: 1rem;
$modal-header-padding: $modal-header-padding-y $modal-header-padding-x; // Keep this for backwards compatibility

$modal-xl: 1140px;
$modal-lg: 800px;
$modal-md: 500px;
$modal-sm: 300px;

$modal-fade-transform: translate(0, -50px);
$modal-show-transform: none;
$modal-transition: transform 0.3s ease-out;

// Alerts
//
// Define alert colors, border radius, and padding.

$alert-padding-y: 0.75rem;
$alert-padding-x: 1.25rem;
$alert-margin-bottom: 1rem;
$alert-border-radius: $border-radius;
$alert-link-font-weight: $font-weight-bold;
$alert-border-width: $border-width;

$alert-bg-level: -10;
$alert-border-level: -9;
$alert-color-level: 6;

// Progress bars

$progress-height: 0.625rem;
$progress-font-size: $font-size-base * 0.75;
$progress-bg: $gray-300;
$progress-border-radius: $border-radius;
$progress-box-shadow: inset 0 0.1rem 0.1rem rgba($black, 0.1);
$progress-bar-color: $white;
$progress-bar-bg: theme-color('primary');
$progress-bar-animation-timing: 1s linear infinite;
$progress-bar-transition: width 0.6s ease;

// List group

$list-group-color: null;
$list-group-bg: $white;
$list-group-border-color: $border-color;
$list-group-border-width: $border-width;
$list-group-border-radius: $border-radius;

$list-group-item-padding-y: 0.75rem;
$list-group-item-padding-x: 1.25rem;

$list-group-hover-bg: $gray-100;
$list-group-active-color: $component-active-color;
$list-group-active-bg: $component-active-bg;
$list-group-active-border-color: $list-group-active-bg;

$list-group-disabled-color: $gray-600;
$list-group-disabled-bg: $list-group-bg;

$list-group-action-color: $gray-700;
$list-group-action-hover-color: $list-group-action-color;

$list-group-action-active-color: $body-color;
$list-group-action-active-bg: $gray-200;

// Image thumbnails

$thumbnail-padding: 0.25rem;
$thumbnail-bg: $body-bg;
$thumbnail-border-width: $border-width;
$thumbnail-border-color: $gray-300;
$thumbnail-border-radius: $border-radius;
$thumbnail-box-shadow: 0 1px 2px rgba($black, 0.075);

// Figures

$figure-caption-font-size: 90%;
$figure-caption-color: $gray-600;

// Breadcrumbs

$breadcrumb-padding-y: 0.75rem;
$breadcrumb-padding-x: 1rem;
$breadcrumb-item-padding: 0.5rem;

$breadcrumb-margin-bottom: 1rem;

$breadcrumb-bg: $gray-200;
$breadcrumb-divider-color: $gray-600;
$breadcrumb-active-color: $gray-600;
$breadcrumb-divider: quote('/');

$breadcrumb-border-radius: $border-radius;

// Carousel

$carousel-control-color: $white;
$carousel-control-width: 15%;
$carousel-control-opacity: 0.5;
$carousel-control-hover-opacity: 0.9;
$carousel-control-transition: opacity 0.15s ease;

$carousel-indicator-width: 30px;
$carousel-indicator-height: 3px;
$carousel-indicator-hit-area-height: 10px;
$carousel-indicator-spacer: 3px;
$carousel-indicator-active-bg: $white;
$carousel-indicator-transition: opacity 0.6s ease;

$carousel-caption-width: 70%;
$carousel-caption-color: $white;

$carousel-control-icon-width: 20px;

$carousel-control-prev-icon-bg: str-replace(
  url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='#{$carousel-control-color}' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e"),
  '#',
  '%23'
);
$carousel-control-next-icon-bg: str-replace(
  url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='#{$carousel-control-color}' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e"),
  '#',
  '%23'
);

$carousel-transition-duration: 0.6s;
$carousel-transition: transform $carousel-transition-duration ease-in-out; // Define transform transition first if using multiple transitions (e.g., `transform 2s ease, opacity .5s ease-out`)

// Spinners

$spinner-width: 2rem;
$spinner-height: $spinner-width;
$spinner-border-width: 0.25em;

$spinner-width-sm: 1rem;
$spinner-height-sm: $spinner-width-sm;
$spinner-border-width-sm: 0.2em;

// Close

$close-font-size: $font-size-base * 1.5;
$close-font-weight: $font-weight-bold;
$close-color: $black;
$close-text-shadow: 0 1px 0 $white;

// Code

$code-font-size: 87.5%;
$code-color: $slate-800;

$kbd-padding-y: 0.2rem;
$kbd-padding-x: 0.4rem;
$kbd-font-size: $code-font-size;
$kbd-color: $white;
$kbd-bg: $gray-900;

$pre-color: $gray-900;
$pre-scrollable-max-height: 340px;

// Utilities

$displays:
  none, inline, inline-block, block, table, table-row, table-cell, flex,
  inline-flex;
$overflows: auto, hidden;
$positions: static, relative, absolute, fixed, sticky;

// Printing

$print-page-size: a3;
$print-body-min-width: map-get($grid-breakpoints, 'lg');
