button.areaBtn:global(.bg-primary-10) {
  &:hover,
  &:focus {
    background-color: var(--primary-10) !important;
  }

  &:focus {
    box-shadow: none;
  }
}
