.cc-input input {
  --l-space: 1px;
  width: 100%;
  letter-spacing: var(--l-space);
  font-size: 1rem;

  max-width: 240px;
  min-width: 240px;
}

@media (min-width: 768px) {
  .cc-input input {
    --l-space: 2px;
    width: calc(19ch + var(--l-space) * 19 + 4px);
    max-width: initial;
    font-size: 1.5rem;
  }
}
