//
// _nav.scss
//

.nav-tabs,
.nav-pills {
  > li {
    > a {
      color: $gray-700;
      font-weight: $font-weight-medium;
    }
  }
}

.nav-pills {
  > a {
    color: $gray-700;
    font-weight: $font-weight-medium;
  }
  a.active {
    &:hover {
      color: #fff !important;
    }
  }
}

.nav-tabs-custom {
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;

  border-bottom: 2px solid transparent;
  gap: 0 0.4rem;

  .nav-item {
    position: relative;

    .nav-link {
      white-space: nowrap;
      color: var(--gray-600);
      border: none;
      font-size: 14px;
      padding: 0.5rem 0.6rem;
      cursor: pointer;

      &:hover:not(.active) {
        &:after {
          background-color: $gray-400;
          transform: scale(1);
        }
      }

      &::after {
        content: '';
        background: transparent;
        height: 2px;
        position: absolute;
        width: 100%;
        left: 0;
        bottom: -1px;
        transition: all 250ms ease 0s;
        transform: scale(0);
      }

      &.active {
        color: $primary;
        cursor: default;
        font-weight: 600;
        background-color: transparent;

        &:after {
          background-color: $primary;
          transform: scale(1);
        }
      }
    }
  }

  &.nav-tabs-custom--bg-transparent {
    .nav-link.active {
      background-color: transparent;
    }
  }
}

// vertical nav

.vertical-nav {
  .nav {
    .nav-link {
      padding: 24px 16px;
      text-align: center;
      margin-bottom: 8px;
      .nav-icon {
        font-size: 24px;
      }
    }
  }
}
