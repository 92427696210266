.calendar-date-picker {
  .react-datepicker__triangle {
    &::before {
      border-bottom-color: var(--surface-30) !important;
    }
    &::after {
      border-bottom-color: white !important;
    }
  }
  .react-datepicker__header {
    background-color: white;
    border-bottom-color: var(--surface-30) !important;
  }
  .react-datepicker__navigation {
    padding-top: 12px;
    .react-datepicker__navigation-icon {
      &::before {
        border-color: var(--text-80);
        border-width: 1px 1px 0 0;
      }
    }
  }
}
.react-datepicker-popper {
  z-index: 50 !important;
}
