@media (max-width: 768px) {
  .bts-modal {
    display: flex !important;
    align-items: flex-end;
    .bts-dialog {
      height: auto !important;
      max-height: 100%;
      width: 100%;
      margin: 0 auto;
      .bts-content {
        min-height: auto !important;
        border-top-right-radius: 0.5rem !important;
        border-top-left-radius: 0.5rem !important;
        .bts-header {
          border-top-right-radius: 0.5rem !important;
          border-top-left-radius: 0.5rem !important;
        }
        .bts-body {
          min-height: auto !important;
          .bts-empty {
            height: 350px !important;
          }
        }
      }
    }
  }
}
