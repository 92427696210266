.genericBanner {
  &:global(.alert-dismissible) {
    padding-right: 1.25rem;

    @media (min-width: 768px) {
      padding-right: 5.7rem;
    }

    :global(.close) {
      top: unset;
      padding: 0.75rem 2.25rem;

      > span {
        display: none;
      }
    }
  }
}
