.selectAddonAppend {
  margin-left: -1px;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  width: 100%;

  border: 0px !important;
}

.datePicker:focus,
.datePicker:focus-within {
  box-shadow: 0 0 0px 2px #777f9e2b !important;
}
