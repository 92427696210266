//
// toastr.scss
//

/* =============
   Notification
============= */

.toast-top-right {
  top: 82px !important;
}

#toast-container {
  > div {
    color: var(--text-black);
    background-color: white;
    opacity: 1;
    width: 345px;

    box-shadow:
      0px 0px 4px 0px rgba(0, 0, 0, 0.04),
      0px 8px 16px 0px rgba(0, 0, 0, 0.08);

    border-radius: 8px;
    border-left-width: 4px;
    border-left-style: solid;
    // default border left color
    border-left-color: var(--tw-secondary-100, #424652);

    // icon size
    background-size: 24px 24px;
    background-position: 14px 14px;

    &:hover {
      box-shadow:
        0px 0px 7px 0px rgba(0, 0, 0, 0.04),
        0px 12px 20px 0px rgba(0, 0, 0, 0.08);
    }
  }

  .toast-message {
    color: var(--tw-text-80, #545454);
  }

  > .toast-success {
    // phosphor-icons // CheckCircle // color: #106632
    background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMiIgaGVpZ2h0PSIzMiIgZmlsbD0iIzEwNjYzMiIgdmlld0JveD0iMCAwIDI1NiAyNTYiPjxwYXRoIGQ9Ik0xMjgsMjRBMTA0LDEwNCwwLDEsMCwyMzIsMTI4LDEwNC4xMSwxMDQuMTEsMCwwLDAsMTI4LDI0Wm00NS42Niw4NS42Ni01Niw1NmE4LDgsMCwwLDEtMTEuMzIsMGwtMjQtMjRhOCw4LDAsMCwxLDExLjMyLTExLjMyTDExMiwxNDguNjlsNTAuMzQtNTAuMzVhOCw4LDAsMCwxLDExLjMyLDExLjMyWiI+PC9wYXRoPjwvc3ZnPg==') !important;
    border-left-color: var(--tw-systemGreen-100, #106632);
  }

  > .toast-error {
    // phosphor-icons // Warning // color: #f64f59
    background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMiIgaGVpZ2h0PSIzMiIgZmlsbD0iI2U3MzIzMiIgdmlld0JveD0iMCAwIDI1NiAyNTYiPjxwYXRoIGQ9Ik0yMzYuOCwxODguMDksMTQ5LjM1LDM2LjIyaDBhMjQuNzYsMjQuNzYsMCwwLDAtNDIuNywwTDE5LjIsMTg4LjA5YTIzLjUxLDIzLjUxLDAsMCwwLDAsMjMuNzJBMjQuMzUsMjQuMzUsMCwwLDAsNDAuNTUsMjI0aDE3NC45YTI0LjM1LDI0LjM1LDAsMCwwLDIxLjMzLTEyLjE5QTIzLjUxLDIzLjUxLDAsMCwwLDIzNi44LDE4OC4wOVpNMTIwLDEwNGE4LDgsMCwwLDEsMTYsMHY0MGE4LDgsMCwwLDEtMTYsMFptOCw4OGExMiwxMiwwLDEsMSwxMi0xMkExMiwxMiwwLDAsMSwxMjgsMTkyWiI+PC9wYXRoPjwvc3ZnPg==') !important;
    border-left-color: var(--tw-systemRed-100, #e73232);
  }

  > .toast-info {
    // phosphor-icons // Info // color: #3290e7
    background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMiIgaGVpZ2h0PSIzMiIgZmlsbD0iIzMyOTBlNyIgdmlld0JveD0iMCAwIDI1NiAyNTYiPjxwYXRoIGQ9Ik0xMjgsMjRBMTA0LDEwNCwwLDEsMCwyMzIsMTI4LDEwNC4xMSwxMDQuMTEsMCwwLDAsMTI4LDI0Wm0tNCw0OGExMiwxMiwwLDEsMS0xMiwxMkExMiwxMiwwLDAsMSwxMjQsNzJabTEyLDExMmExNiwxNiwwLDAsMS0xNi0xNlYxMjhhOCw4LDAsMCwxLDAtMTYsMTYsMTYsMCwwLDEsMTYsMTZ2NDBhOCw4LDAsMCwxLDAsMTZaIj48L3BhdGg+PC9zdmc+') !important;
    border-left-color: var(--tw-systemBlue-100, #3290e7);
  }

  > .toast-warning {
    // phosphor-icons // WarningCircle // color: #e9c547
    background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMiIgaGVpZ2h0PSIzMiIgZmlsbD0iI2U5YzU0NyIgdmlld0JveD0iMCAwIDI1NiAyNTYiPjxwYXRoIGQ9Ik0xMjgsMjRBMTA0LDEwNCwwLDEsMCwyMzIsMTI4LDEwNC4xMSwxMDQuMTEsMCwwLDAsMTI4LDI0Wm0tOCw1NmE4LDgsMCwwLDEsMTYsMHY1NmE4LDgsMCwwLDEtMTYsMFptOCwxMDRhMTIsMTIsMCwxLDEsMTItMTJBMTIsMTIsMCwwLDEsMTI4LDE4NFoiPjwvcGF0aD48L3N2Zz4=') !important;
    border-left-color: var(--systemGold-100, #e9c547);
  }
}
