.cardButtons {
  flex-direction: column;

  gap: var(--gap);
}

@media (min-width: 768px) {
  .cardButtons {
    --btn-cols: var(--cols, 2);

    flex-direction: row;
    flex-wrap: wrap;
  }
  .cardButtons > * {
    flex-basis: calc(
      calc(100% / var(--btn-cols)) -
        calc(calc(var(--btn-cols) - 1) * var(--gap) / var(--btn-cols))
    );
    flex-shrink: 0;
  }
}
